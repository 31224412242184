import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const CoralBlock = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="coral-block"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M22.3169 14.2214L35.8211 11.0084L41.3588 14.7844L41.349 14.8232L27.8546 17.9975L22.3169 14.2214Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M41.3481 14.8245L40.0656 21.6971L26.5518 24.9101L27.8536 17.9987L41.3481 14.8245Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M27.8537 17.9982L26.5519 24.9096L21.0239 21.1336L22.3161 14.2221L27.8537 17.9982Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M26.562 24.8983L21.034 21.0737L14.5151 22.5978L14.5054 22.6366L20.0333 26.4126L26.562 24.8983Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M14.5053 22.6381L12.0376 35.7912L17.5656 39.6061L20.0332 26.4141L14.5053 22.6381Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M20.0326 26.4134L17.5649 39.6054L24.0936 38.0814L26.5612 24.8991L20.0326 26.4134Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M39.2892 25.8595L27.8543 17.9773L21.3354 19.5013L21.3257 19.5401L32.7605 27.3835L39.2892 25.8595Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M21.3263 19.5403L20.0342 26.4129L31.469 34.2854L32.7611 27.3836L21.3263 19.5403Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M32.7604 27.3841L31.4683 34.2859L37.9872 32.7619L39.289 25.8601L32.7604 27.3841Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M22.3273 14.1727L10.9022 6.29053L4.37354 7.81454V7.85337L15.8084 15.6967L22.3273 14.1727Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M4.37317 7.85352L3.08105 14.7261L14.5159 22.5986L15.808 15.6969L4.37317 7.85352Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M15.8087 15.696L14.5166 22.5978L21.0355 21.0737L22.3277 14.172L15.8087 15.696Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M30.3213 4.81464L24.7933 0.999756L18.2744 2.51406L18.2646 2.56259L23.7926 6.33866L30.3213 4.81464Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M18.2656 2.56342L15.8076 15.7068L21.3259 19.5217L23.7935 6.33948L18.2656 2.56342Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M23.7934 6.33921L21.3257 19.5215L27.8543 18.0071L30.322 4.81519L23.7934 6.33921Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M2.29297 18.9388L15.7972 15.7257L21.3348 19.5018L21.3251 19.5406L7.82094 22.7245L2.29297 18.9388Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M21.3241 19.5411L20.032 26.4138L6.52783 29.6268L7.81996 22.7251L21.3241 19.5411Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M7.82009 22.7245L6.52796 29.6263L1 25.8502L2.29212 18.9388L7.82009 22.7245Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </Box>
  );
};
