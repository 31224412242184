import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
export const FooterIllustrationThree = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="footer-illustration-three"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M31.5999 26.7746L21.6093 38.6109L11.3547 32.1787L29.201 23.5259L7.84459 19.2226L20.5165 11.656L30.4931 18.2373L29.2462 5.47742L41.6608 13.0306L36.7677 20.2389L45.4688 16.5132L52.8565 27.0826L38.979 25.6452L44.1688 35.1268L31.0186 39.3036L31.5999 26.7746Z"
        fill="#F9B233"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M42.552 42.1969L44.1691 35.1267L31.0189 39.3035L29.4018 46.3737L42.552 42.1969Z"
        fill="#F7C091"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.6008 26.7741L29.9837 33.8443L29.4023 46.3733L31.0195 39.3031L31.6008 26.7741Z"
        fill="#EC8068"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6099 38.611L20.0062 45.6848L29.9834 33.8449L31.6006 26.7747L21.6099 38.611Z"
        fill="#F7C091"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.7464 39.2436L11.355 32.1783L21.6096 38.6106L20.0059 45.6844L9.7464 39.2436Z"
        fill="#EC8068"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57711 27.6229L7.84491 19.2223L29.2014 23.5256L17.4969 29.2016L8.57711 27.6229Z"
        fill="#F7C091"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.8566 27.0826L51.2394 34.1528L43.1122 33.1981L38.979 25.6452L52.8566 27.0826Z"
        fill="#EC8068"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6612 13.0305L41.1747 18.3549L36.768 20.2388L41.6612 13.0305Z"
        fill="#EC8068"
        stroke="#1D1D1B"
        // style="fill:#EC8068;fill:color(display-p3 0.9255 0.5020 0.4078);fill-opacity:1;stroke:#1D1D1B;stroke:color(display-p3 0.1137 0.1137 0.1059);stroke-opacity:1;"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  );
};
