import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const DownArrow3D = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="down-arrow-3D"
      viewBox="0 0 21 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M10.846 74.9062L1.14357 60.7795L20.543 60.7795L10.846 74.9062Z"
        fill="#E4B36C"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8457 74.9059L3.69421 60.5974L1.14325 60.7792L10.8457 74.9059Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 60.2947C1 61.8496 5.40807 63.1101 10.8457 63.1101C16.2833 63.1101 20.6914 61.8496 20.6914 60.2947C20.6914 58.7398 16.2833 57.4792 10.8457 57.4792C5.40807 57.4792 1 58.7398 1 60.2947Z"
        fill="#D99B52"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.846 61.2094C13.8212 61.2094 16.2344 60.5207 16.2344 59.6722C16.2344 59.6502 16.2289 59.6281 16.2234 59.6061L16.2234 2.53735L5.45754 2.53735L5.45754 59.6722C5.45754 60.5207 7.87076 61.2094 10.846 61.2094Z"
        fill="#E4B36C"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.54834 2.53735L5.45743 2.53735L5.45743 59.6722C5.45743 60.0193 5.86514 60.3389 6.54834 60.5978L6.54834 2.53735Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.45754 2.53719C5.45754 3.38615 7.87002 4.07437 10.846 4.07437C13.8219 4.07437 16.2344 3.38615 16.2344 2.53719C16.2344 1.68822 13.8219 1 10.846 1C7.87002 1 5.45754 1.68822 5.45754 2.53719Z"
        fill="#D99B52"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  );
};
