import React from 'react';

import { MotionBox } from 'components/MotionElement';
import { useAnimationWhenPathUnMatch } from './useAnimationWhenPathUnMatch';

export function AnimatedLinkDecoration({
  to,
  initial,
  animate,
  exit,
  decorationInitial,
  decorationAnimate,
  DecorationElement,
  sx
}) {
  const animationVariants = {
    initial,
    animate,
    exit
  };

  const decorationAnimation = {
    initial: decorationInitial,
    animate: decorationAnimate
  };

  const controls = useAnimationWhenPathUnMatch({
    to,
    animate: decorationAnimation.animate
  });

  return (
    <MotionBox
      variants={animationVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        position: 'absolute',
        ...sx
      }}
      transition={{
        duration: 0.3
      }}>
      <DecorationElement
        initial={decorationAnimation.initial}
        animate={controls}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 0.5
        }}
      />
    </MotionBox>
  );
}
