module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/src/intl","languages":["en"],"locales":{"default":"en","translations":[]},"translatedPaths":[{"default":"contact","de":"kontakt"},{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"}],"secondPath":null,"redirect":false,"wrapProvider":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://the-puzzlers.matomo.cloud/","siteUrl":"https://thepuzzlers.com","disableCookies":true,"respectDnt":false},
    },{
      plugin: require('../node_modules/gatsby-source-keepitsimple/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"the-puzzlers-v5"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
