import React from 'react';

// External Components
import { AspectRatioBox } from './AspectRatioBox';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const AspectRatioImageContainer = ({
  src,
  alt,
  aspectRatios,
  className = '',
  designatedRef,
  sx,
  imgProps,
  imgStyle,
  children,
  imageSx,
  ...props
}) => {
  return (
    <AspectRatioBox
      ref={designatedRef}
      className={`image-container ${className}`}
      aspectRatio={aspectRatios}
      sx={{
        position: 'relative',
        // padding is refer to parrent element so it will not work if this element has width is set, it will still refer the the width of paret element
        // https://css-tricks.com/oh-hey-padding-percentage-is-based-on-the-parent-elements-width/
        // pt: getPaddingPercentageForAspectRatio(aspectRatios),
        width: !sx?.width && !sx?.height ? '100%' : null,
        ...sx
      }}
      {...props}>
      <GatsbyImage
        className="gatsby-image"
        image={getImage(src)}
        alt={alt ? alt : ''}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          ...imageSx
        }}
        imgStyle={imgStyle}
        {...imgProps}
      />
      {/* Children meant to be use for adding any absolute style decoration */}
      {children}
    </AspectRatioBox>
  );
};
