import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ServiceSelectLine = ({ sx, ...props }) => {
  return (
    <Box
      className="services-select-line"
      as="svg"
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'texts.primaryInvert',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M1 29L18 1H34.5"
        stroke="currentColor"
        style={{ stroke: 'currentColor', strokeOpacity: 1 }}
        strokeWidth="2"
      />
    </Box>
  );
};
