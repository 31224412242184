import React from 'react';

// External components
import { GridWrapper, Box } from '@thepuzzlers/pieces';
import {
  NavigationLink,
  LanguageSwitchLink
} from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  PrimaryButton,
  StackedThePuzzlersLogo,
  ThePuzzlersLogo
} from 'components';

// Data

// Helper function
import { Z_INDEX } from 'constants/zIndex';
import { NavigationLinkItem } from './NavigationLinkItem';
import { fillBgOnScroll, resetNavColorWhenOverlayOpen } from './animation';
import { AnimatedToggleButton } from './AnimatedToggleButton';
import { useTransparentBackgroundCheck } from '../helper/useTransparentBackroundCheck';
import { useLocation } from '@reach/router';
import { CaseStudyLinkArrow } from 'components/svgs/CaseStudyLinkArrow';
import { ServicesLinkArrow } from 'components/svgs/ServicesLinkArrow';
import { AboutLinkArrow } from 'components/svgs/AboutLinkArrow';
import { StudioLinkArrow } from 'components/svgs/StudioLinkArrow';
import { AnimatedLinkDecoration } from './AnimatedLinkDecoration';

export const NavigationBar = ({
  navigationData,
  sx,
  isOverlayOpen,
  setIsOverlayOpen
}) => {
  // state animation value
  const { menuBtn, pageLinks, contactLink, legalLink, closeBtn } =
    navigationData;

  const isTransparentBackground = useTransparentBackgroundCheck();
  const { pathname } = useLocation();

  React.useEffect(() => {
    const killFunction = fillBgOnScroll(isTransparentBackground);
    return killFunction;
  }, [isTransparentBackground, pathname]); // listen as well to the path change

  React.useEffect(() => {
    // reset the navigation color when eht overlay open
    if (isTransparentBackground) {
      const navColorResetAnimation =
        resetNavColorWhenOverlayOpen(isOverlayOpen);

      // play animation when the overlay open
      navColorResetAnimation && isOverlayOpen && navColorResetAnimation.play(0);
      // reverse the animation when the overlay closed
      navColorResetAnimation &&
        !isOverlayOpen &&
        navColorResetAnimation.reverse(0);

      return () => {
        navColorResetAnimation?.pause(0).kill(true);
        navColorResetAnimation?.scrollTrigger?.kill(true);
      };
    }
  }, [isOverlayOpen, isTransparentBackground]);

  return (
    // Markup
    <ContentWrapper
      sx={sx}
      isOverlayOpen={isOverlayOpen}
      isTransparentBackground={isTransparentBackground}>
      <Logo />
      <NavigationLinks links={pageLinks} />
      <ContactLink link={contactLink} />
      <MenuButton
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
        openText={menuBtn}
        closeText={closeBtn}
      />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children, sx }) => {
  return (
    <GridWrapper
      as="nav"
      className="navigation-bar-wrapper"
      sx={{
        position: 'sticky',
        py: ['1.5rem', '1.5rem', '1.7rem', '2.1rem'],
        top: '-1px',
        left: 0,
        width: '100%',
        zIndex: Z_INDEX.navigationBar,
        ...sx
      }}>
      {children}
    </GridWrapper>
  );
};

const Logo = () => (
  <NavigationLink
    to="/"
    sx={{
      alignSelf: 'center',
      gridColumnStart: 1,
      width: ['7.4rem', '15.5rem', '15.5rem', '15.5rem']
    }}>
    <ThePuzzlersLogo
      className="__navigation-bar-the-puzzlers-logo"
      sx={{
        display: ['none', 'block', 'block', 'block']
      }}
    />
    <StackedThePuzzlersLogo
      className="__navigation-bar-the-puzzlers-logo"
      sx={{
        display: ['block', 'none', 'none', 'none']
      }}
    />
  </NavigationLink>
);

const ContactLink = ({ link: { label, to } }) => {
  return (
    <PrimaryButton
      to={to}
      variant="links.animatedNavigation"
      sx={{
        gridColumn: [7, '19/ span 4', '22/25', '22/25'],
        justifySelf: [null, 'center', 'end', 'end'],
        alignSelf: 'center',
        position: 'relative'
      }}>
      {label}
    </PrimaryButton>
  );
};

const MenuButton = ({
  isOverlayOpen,
  setIsOverlayOpen,
  openText,
  closeText
}) => {
  const handleToggleOpenOverlay = () => {
    setIsOverlayOpen((state) => !state);
  };

  return (
    <AnimatedToggleButton
      className="menu-button"
      isAnimated
      sx={{
        gridColumnEnd: [13, 25, null, null],
        display: ['block', 'block', 'none', 'none'],
        justifySelf: 'end',
        alignSelf: 'center'
      }}
      isOpen={isOverlayOpen}
      onClick={handleToggleOpenOverlay}
      openText={openText}
      closeText={closeText}
    />
  );
};

const NavigationLinks = ({ links }) => {
  const linkDecorations = [
    ServiceLinkDecoration,
    StudioLinkDecoration,
    CaseStudyLinkDecoration,
    AboutLinkDecoration
  ];

  const linksPairedWithDecoration = links.map((link, index) => ({
    link,
    Decoration: linkDecorations[index]
  }));

  return (
    <Box
      className="__navigation-links"
      sx={{
        gridColumn: [null, null, '5/ span 17', '5/ span 16'],
        display: ['none', 'none', 'flex', 'flex'],
        justifyContent: 'center',
        alignSelf: 'center'
      }}>
      {linksPairedWithDecoration.map(
        ({ link: { label, to }, Decoration }, index) => {
          return (
            <NavigationLinkItem key={index} label={label} to={to}>
              <Decoration to={to} />
            </NavigationLinkItem>
          );
        }
      )}
    </Box>
  );
};

function CaseStudyLinkDecoration({ to }) {
  return (
    <AnimatedLinkDecoration
      to={to}
      initial={{
        y: -20,
        x: -20,
        opacity: 0
      }}
      animate={{ y: 0, x: 0, opacity: 1 }}
      exit={{
        y: -20,
        x: -20,
        opacity: 0
      }}
      decorationInitial={{
        x: -10,
        y: -10,
        rotate: '45deg'
      }}
      decorationAnimate={{
        x: 0,
        y: 0
      }}
      DecorationElement={CaseStudyLinkArrow}
      sx={{
        top: '-3rem',
        left: '0.3rem',
        width: '4.413rem'
      }}
    />
  );
}

function ServiceLinkDecoration({ to }) {
  return (
    <AnimatedLinkDecoration
      DecorationElement={ServicesLinkArrow}
      to={to}
      initial={{
        y: -15,
        x: 15,
        opacity: 0
      }}
      animate={{
        y: 0,
        x: 0,
        opacity: 1
      }}
      exit={{
        y: -15,
        x: 15,
        opacity: 0
      }}
      decorationInitial={{
        rotate: 140,
        y: -10,
        x: 10
      }}
      decorationAnimate={{
        y: 0,
        x: 0
      }}
      sx={{
        top: '-3rem',
        right: '-2.9rem',
        width: '4.648rem'
      }}
    />
  );
}

function AboutLinkDecoration({ to }) {
  return (
    <AnimatedLinkDecoration
      to={to}
      initial={{
        y: -20
      }}
      animate={{
        y: 0
      }}
      exit={{
        y: -20
      }}
      decorationInitial={{
        rotate: -120,
        y: -10
      }}
      decorationAnimate={{
        y: 0
      }}
      DecorationElement={AboutLinkArrow}
      sx={{
        top: '-3rem',
        left: '-1.3rem',
        width: '3.54rem'
      }}
    />
  );
}

function StudioLinkDecoration({ to }) {
  const animationVariants = {
    initial: {
      rotate: -58,
      y: -10,
      x: 5
    },
    animate: {
      y: 0,
      x: 0
    },
    exit: {
      y: -100,
      x: 100
    }
  };

  return (
    <AnimatedLinkDecoration
      to={to}
      initial={{
        y: -25,
        x: 20
      }}
      animate={{
        y: 0,
        x: 0
      }}
      exit={{
        y: -25,
        x: 20
      }}
      decorationInitial={{
        rotate: -58,
        y: -10,
        x: 5
      }}
      decorationAnimate={{
        y: 0,
        x: 0
      }}
      DecorationElement={StudioLinkArrow}
      sx={{
        top: '-2.5rem',
        right: '-2.5rem',
        width: '3.814rem'
      }}
    />
  );
}

// Reusable Components

// TODO: 'REMOVE THIS COMPONENT LATER
export const LangSwitchLink = ({ text, language, sx, onClick }) => (
  <LanguageSwitchLink
    variant="buttons.clear"
    sx={{
      fontFamily: 'primary.normal',
      fontSize: ['1.6rem', '1.6rem', '1.8rem', '1.4rem', '1.5rem', '1.5rem'],
      lineHeight: 1,
      textTransform: 'uppercase',
      p: [
        '1.4rem 1rem',
        '1.4rem 1rem',
        '1.5rem 1rem',
        '1.1rem 1rem',
        '1.6rem 1.2rem',
        '1.6rem 1.2rem'
      ],
      position: 'relative',
      zIndex: 1,
      '&:hover, &.active ': {
        bg: 'secondary'
      },
      ...sx
    }}
    language={language}
    onClick={onClick}>
    {text}
  </LanguageSwitchLink>
);
