import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { MotionBox } from 'components/MotionElement';

export const CaseStudyLinkArrow = ({ sx, ...props }) => {
  return (
    <MotionBox
      as="svg"
      className="case-study-link-arrow"
      viewBox="0 0 26 12"
      fill="none"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M18.8291 8.87705L17.0234 9.07023L17.2915 5.01154L19.0952 4.81836L18.8291 8.87705Z"
        fill="#787878"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4077 4.01203L15.6651 4.44175L1.27734 2.06449L3.01791 1.63477L17.4077 4.01203Z"
        fill="#787878"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3869 7.84593L15.8648 1.42969L15.6657 4.4397L1.27794 2.06441L1 6.27095L15.3878 8.64624L15.1887 11.6563L23.3849 7.84593H23.3869ZM17.3846 5.54555L19.5963 7.43198L17.1855 8.55162L17.3846 5.54358V5.54555Z"
        fill="#8F9193"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1259 7.41625L23.3854 7.84597L15.8633 1.42972L17.6058 1L25.1259 7.41625Z"
        fill="#787878"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1302 7.41602L23.3896 7.84574L15.1914 11.658L16.9339 11.2283L25.1302 7.41602Z"
        fill="#787878"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2734 7.1305L18.9391 7.03588L18.949 6.88804L17.3779 5.54565L17.2734 7.1305Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5958 5.43506L21.1602 5.5415"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9852 7.71973L17.4688 7.84588"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.7879 1.7688L2.63672 2.05265"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.7859 2.08008L3.49219 2.15301"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.463 6.13501L22.0273 6.24145"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6927 5.70117L21.6953 5.94757"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </MotionBox>
  );
};
