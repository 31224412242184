import React from 'react';

import { AnimatePresence } from 'framer-motion';

// Local Components
import { NavigationBar } from './components';
import { NavigationOverlay } from './navigationOverlay';
import { useNavigationData } from './useNavigationData';

// Data

export const Navigation = () => {
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const navigationData = useNavigationData();

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  return (
    // Markup
    <>
      <NavigationBar
        navigationData={navigationData}
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
      />
      <AnimatePresence>
        {isOverlayOpen && (
          <NavigationOverlay
            handleClose={handleCloseOverlay}
            navigationData={navigationData}
          />
        )}
      </AnimatePresence>
    </>
  );
};
