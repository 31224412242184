import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const NavIllustrationThree = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="nav-illustration-three"
      viewBox="0 0 40 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M5.46733 12.1343L18.1224 41.5936L35.7899 31.2546L15.7663 6.07272C14.5674 4.23612 11.732 3.42514 8.34491 5.43844C5.26392 7.40201 4.56115 10.0248 5.35965 11.9078L5.38965 11.9598C5.41965 12.0117 5.44098 12.0687 5.47098 12.1207L5.46733 12.1343Z"
        fill="#F1B6C7"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.211 43.3437C19.4537 50.3396 11.6177 37.6471 23.1576 30.3148C34.9148 23.319 42.7509 36.0114 31.211 43.3437Z"
        fill="#C0677E"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  );
};
