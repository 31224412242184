import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const Diamond = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="illustration-diamond"
      viewBox="0 0 39 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M36.643 6.44077L30.3085 0.955453L12.9845 0.389648L1.99512 5.30918L8.32963 10.7945L25.6536 11.3603L36.643 6.44077Z"
        fill="#F3955B"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M27.3435 17.3636L25.6543 11.3605L36.6437 6.44096L38.3329 12.4345L27.3435 17.3636Z"
        fill="#D36760"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.33043 10.7945L25.6543 11.3603L27.3436 17.3635L6.84277 16.5388L8.33043 10.7945Z"
        fill="#ED6C5E"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M1.99578 5.30873L0.498535 11.053L6.84264 16.5383L8.3303 10.794L1.99578 5.30873Z"
        fill="#CF513C"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M27.344 17.3616L18.2358 34.4409L6.84326 16.5369L27.344 17.3616Z"
        fill="#BD556C"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M18.2354 34.4418L38.333 12.4334L27.3436 17.3626L18.2354 34.4418Z"
        fill="#974748"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M0.498535 11.0528L6.84264 16.5381L18.2352 34.4421L0.498535 11.0528Z"
        fill="#553C47"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </Box>
  );
};
