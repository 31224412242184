import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { gsap } from 'gsap';
import { ButtonLine, DynamicButton } from 'components';

export const AnimatedToggleButton = ({
  className,
  isAnimated,
  isOpen,
  sx,
  openText,
  closeText,
  ...props
}) => {
  const [tl, setTl] = React.useState(null);

  React.useLayoutEffect(() => {
    if (className && isAnimated) {
      const buttonLine = document.querySelector(`.${className} .__button-line`);
      const openText = document.querySelector(`.${className} .__open-text`);
      const closeText = document.querySelector(`.${className} .__close-text`);

      const tl = gsap.timeline();
      tl.to(buttonLine, {
        bottom: 0,
        top: 'unset',
        duration: 0,
        height: '100%',
        duration: 0.3,
        ease: 'power4.in'
      })
        .to(openText, {
          opacity: 0,
          duration: 0
        })
        .to(
          closeText,
          {
            opacity: 1,
            duration: 0
          },
          '<'
        )
        .to(buttonLine, {
          top: 'unset',
          bottom: '100%',
          duration: 0,
          height: '2px',
          duration: 0.3,
          ease: 'power4.out'
        })
        .pause(0);
      setTl(tl);
    }

    return () => {
      tl?.pause(0)?.kill(true);
    };
  }, []);

  React.useLayoutEffect(() => {
    tl && isOpen && tl.play(0);
    tl && !isOpen && tl.reverse(0);
  }, [isOpen]);

  return (
    <DynamicButton
      className={className}
      variant="links.animatedNavigation"
      sx={{
        position: 'relative',
        ...sx
      }}
      {...props}>
      <Box
        // Box needed as animation requirement
        className="__animated-toggle-button-text-container"
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center'
        }}>
        {/* 
        //? Extra span is needed for animation purpose. the inner span 
        //? is used to color change, based on page bg, and the outer span 
        //? with class name is used to show or unShow the texts based on overlay state 
        */}

        <Box as="span" className="__open-text __menu-button-text">
          <Box as="span">{openText}</Box>
        </Box>
        <Box
          as="span"
          className="__close-text __menu-button-text"
          sx={{
            position: 'absolute',
            opacity: 0
          }}>
          <Box as="span">{closeText}</Box>
        </Box>
      </Box>
      <ButtonLine />
    </DynamicButton>
  );
};
