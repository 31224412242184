import React from 'react';

// External components
import { GridWrapper } from '@thepuzzlers/pieces';

// Data
import { useFooterData } from './useFooterData';
import {
  Heading,
  Box,
  Link
} from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';
import {
  FooterIllustrationOne,
  FooterIllustrationThree,
  FooterIllustrationTwo,
  GridSpacer
} from 'components';
import { useNavigationData } from 'sections/Navigation/useNavigationData';

export const Footer = ({ isTransparentBackground }) => {
  const { headline } = useFooterData();
  const { pageLinks, legalLink } = useNavigationData();

  const navigationLinks = [...pageLinks, legalLink];

  return (
    // Markup
    <ContentWrapper isTransparentBackground={isTransparentBackground}>
      <HeadlineWithDecoration
        headline={headline}
        isTransparentBackground={isTransparentBackground}
      />
      <NavLinks
        navigationData={navigationLinks}
        isTransparentBackground={isTransparentBackground}
      />
      <BottomSpacer />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children, isTransparentBackground }) => (
  <GridWrapper
    as="footer"
    sx={{
      position: 'relative'
    }}>
    {children}
  </GridWrapper>
);

const HeadlineWithDecoration = ({ headline, isTransparentBackground }) => {
  return (
    <Box
      className="__children-with-decoration"
      sx={{
        gridColumn: ['1/ span 12', '1/ span 18', '1/ span 17', '1/ span 16'],
        width: ['100%', '54.1rem', '72.1rem', '86.5rem'],
        mt: ['1.7rem', 0, 0, 0],
        position: 'relative'
      }}>
      <Heading
        as="h2"
        sx={{
          fontFamily: 'primary.normal',
          fontSize: ['8.8rem', '15rem', '20rem', '24rem'],
          lineHeight: 1,
          color: isTransparentBackground
            ? 'texts.primaryDark'
            : 'texts.primaryInvert'
        }}
        dangerouslySetInnerHTML={{ __html: headline }}
      />

      {/* DECORATION */}
      <FooterIllustrationOne
        initial={{
          y: 0,
          scale: 1
        }}
        animate={{
          y: 8,
          scale: 1.1
        }}
        transition={{
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 2
        }}
        sx={{
          position: 'absolute',
          top: [0, '-6.7rem', '2.6rem', '3.3rem'],
          // right: ['3.834rem', '24.844rem', '36.4rem', '59.044rem'],
          right: ['1.2rem', '1.8rem', '-1.3rem', '8rem'],
          width: ['4.956rem', '6.4rem', '6rem', '6.756rem']
        }}
      />
      <FooterIllustrationTwo
        initial={{
          y: 0,
          x: 3,
          scale: 1
        }}
        animate={{
          y: -8,
          x: 0,
          scale: 1.1
        }}
        transition={{
          ease: 'easeIn',
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 2
        }}
        sx={{
          position: 'absolute',
          top: ['24.411rem', '3rem', '11.2rem', '13.69rem'],
          right: ['2rem', '-8.3rem', '-9.5rem', '-0.8rem'],
          width: ['3.368rem', '4.829rem', '3.5rem', '4.217rem'],
          transform: 'rotate(45deg)'
        }}
      />
      <FooterIllustrationThree
        initial={{
          y: 0,
          x: -3,
          scale: 1
        }}
        animate={{
          y: 10,
          x: 3,
          scale: 1.1
        }}
        transition={{
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 2.3
        }}
        sx={{
          position: 'absolute',
          width: ['6rem', '7.4rem', '6.5rem', '7.9rem'],
          top: ['30.6rem', '7rem', '13.4rem', '15.5rem'],
          // left: ['25rem', '45rem', '71.4rem']
          right: ['3.9rem', '7.5rem', '3.5rem', '14.7rem']
        }}
      />
    </Box>
  );
};

const NavLinks = ({ navigationData, isTransparentBackground }) => {
  return (
    <Box
      as="ul"
      sx={{
        gridColumn: ['1/ span 11', '20/ span 5', '20/ span 5', '19/ span 6'],
        mt: ['5.7rem', 0, 0, 0],
        mb: [0, '1.6rem', '2.3rem', '2.9rem'],
        alignSelf: ['auto', 'end', 'end', 'end']
      }}>
      {navigationData.map((navItem, index) => {
        return (
          <Box
            key={index}
            as="li"
            sx={{
              fontFamily: 'body.normal',
              fontSize: ['1.6rem', '1.4rem', '1.6rem', '1.6rem']
            }}>
            <Link
              href={navItem.to}
              sx={{
                lineHeight: 1.75,
                transition: 'color 200ms ease',
                color: isTransparentBackground
                  ? 'texts.primaryDark'
                  : 'texts.primaryInvert',
                '&:hover': {
                  color: 'hoverLink'
                }
              }}>
              {navItem.label}
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};

const TopSpacer = () => {
  return <GridSpacer height={['11rem', '15rem', '15rem', '15rem']} />;
};

const BottomSpacer = () => {
  return <GridSpacer height={['10rem', '4.7rem', '5.3rem', '5rme']} />;
};
