import { colors } from 'gatsby-plugin-theme-ui/colors';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const fillBgOnScroll = (isTransparentBackground) => {
  const navElement = document.querySelector('nav');
  const bodyElement = document.querySelector('body');

  const navigationLinks = document.querySelectorAll(
    '.navigation-bar-wrapper span:not(.__menu-button-text)'
  );

  const navigationButtonLine = document.querySelectorAll(
    '.navigation-bar-wrapper .__button-line'
  );

  const thePuzzlersLogo = document.querySelectorAll(
    '.__navigation-bar-the-puzzlers-logo'
  );

  let tl;

  gsap.set([navigationLinks, thePuzzlersLogo], {
    clearProps: true
  });

  if (isTransparentBackground) {
    gsap.set([navigationLinks, thePuzzlersLogo], {
      color: `${colors.primitive.spaceCadet}`
    });
    gsap.set([navigationButtonLine], {
      backgroundColor: `${colors.primitive.spaceCadet}`
    });
  } else {
    gsap.set([navigationLinks, thePuzzlersLogo], {
      color: `${colors.text}`
    });
    gsap.set([navigationButtonLine], {
      backgroundColor: `${colors.text}`
    });
  }

  tl = gsap.timeline({
    ease: 'power1.in',
    scrollTrigger: {
      trigger: bodyElement,
      start: `${navElement.offsetHeight} top`,
      endTrigger: bodyElement,
      end: 'bottom top',
      toggleActions: 'play none none reverse'
    }
  });

  tl.fromTo(
    navElement,
    {
      backgroundColor: isTransparentBackground
        ? '#FFFFFF00'
        : `${colors.primitive.spaceCadet}00`,
      duration: 0.2
    },
    {
      backgroundColor: isTransparentBackground
        ? 'white'
        : `${colors.primitive.spaceCadet}FF`,
      duration: 0.2
    }
  );

  return () => {
    tl?.pause(0)?.kill()?.progress(0);
    tl?.scrollTrigger?.kill(true);
  };
};

export const resetNavColorWhenOverlayOpen = (isOverlayOpen) => {
  let tl;

  function animateColorChangeWhenOverlayOpen() {
    const navElement = document.querySelector('nav');

    const navigationLinks = document.querySelectorAll(
      '.navigation-bar-wrapper span'
    );

    const navigationButtonLine = document.querySelectorAll(
      '.navigation-bar-wrapper .__button-line'
    );

    const thePuzzlersLogo = document.querySelectorAll(
      '.__navigation-bar-the-puzzlers-logo'
    );

    tl = gsap.timeline().pause(0);

    tl.to(navElement, {
      backgroundColor: `#FFFFFF00`,
      duration: isOverlayOpen ? 0.2 : 0.6
    });

    tl.to(
      [navigationLinks, thePuzzlersLogo],
      {
        color: `${colors.text}`
      },
      '<'
    );

    tl.to(
      navigationButtonLine,
      {
        backgroundColor: `${colors.text}`
      },
      '<'
    );
    return () => {
      tl?.pause(0)?.kill(true);
      tl?.scrollTrigger?.kill(true);
    };
  }

  ScrollTrigger.matchMedia({
    '(max-width: 1000px)': animateColorChangeWhenOverlayOpen
  });

  return tl;
};
