import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ServiceCircle = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 848 857"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <g class="services-circle-container">
        <g class="icon-0">
          <g class="icon-Group">
            <path
              fill="color(display-p3 .9765 .698 .2)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m118.162 672.073-12.772 15.131-13.108-8.222 22.813-11.061-27.3-5.501 16.198-9.673 12.754 8.413-1.594-16.311 15.87 9.655-6.255 9.215 11.123-4.763 9.443 13.511-17.74-1.837 6.635 12.12-16.81 5.34.743-16.017Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .7529 .5686)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m132.162 691.789 2.068-9.038-16.811 5.339-2.067 9.038 16.81-5.339Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9255 .502 .4078)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m118.163 672.073-2.067 9.038-.743 16.016 2.067-9.038.743-16.016Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .7529 .5686)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m105.391 687.204-2.05 9.043 12.754-15.135 2.068-9.038-12.772 15.13Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9255 .502 .4078)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m90.226 688.013 2.056-9.031 13.109 8.222-2.05 9.043-13.115-8.234Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .7529 .5686)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m88.732 673.158-.936-10.739 27.3 5.501-14.962 7.256-11.402-2.018Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9255 .502 .4078)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m145.335 672.468-2.068 9.038-10.389-1.221-5.283-9.655 17.74 1.838Z"
              class="icon-Vector"
            />
          </g>
          <path
            fill="color(display-p3 .9255 .502 .4078)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m131.023 654.504-.622 6.807-5.633 2.408 6.255-9.215Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-1">
          <path
            fill="color(display-p3 .9765 .698 .2)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m185.029 706.678-19.217 18.362-7.482.923 22.753-18.743 3.946-.542Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m166.586 744.407-6.795 4.586-1.354-21.23-.108-1.8 7.483-.923.774 19.367Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5922 .2784 .2824)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m192.291 751.215-25.704-6.808-.774-19.368 19.217-18.361 16.315 7.962-1.877 7.598-7.177 28.977Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .3333 .2353 .2784)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m192.29 751.216-4.942 3.855-27.557-6.078 6.795-4.585 25.704 6.808Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-2">
          <path
            fill="color(display-p3 .2471 .2431 .5098)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m226.063 750.918 3.826 10.149 9.841 14.115-3.817-10.157-9.85-14.107Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5176 .4863 .6745)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m252.696 742.84 3.817 10.158-.331 17.193-3.808-10.148.322-17.203Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7294 .6902 .8431)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m268.626 765.679-16.452 4.991-.332 17.194-9.849-14.107-16.452 4.991 10.372-13.724-9.85-14.106 16.261 5.646 10.373-13.724-.323 17.202 16.252 5.637Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5176 .4863 .6745)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m268.626 765.679 3.817 10.158-16.461 4.982-3.808-10.149 16.452-4.991Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .2471 .2431 .5098)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m251.843 787.863 3.817 10.158.322-17.203-3.808-10.148-.331 17.193Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .4392 .4235 .5961)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m241.992 773.757 3.826 10.149 9.841 14.115-3.817-10.157-9.85-14.107Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .2471 .2431 .5098)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m225.541 778.748 3.808 10.148 16.47-4.991-3.826-10.148-16.452 4.991Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-3">
          <path
            fill="color(display-p3 .9216 .5137 .2549)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m315.696 804.184-22.836-9.551 31.171-19.639-8.335 29.19Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9137 .7255 .4118)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m344.974 835.224-29.277-31.041 8.335-29.189 20.942 60.23Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .498 .302 .1569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m344.974 835.224-52.115-40.591 22.837 9.55 29.278 31.041Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-4">
          <path
            fill="color(display-p3 .9529 .4471 .3882)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="m412.416 845.177-31.014-8.717 15.717-14.149 10.933 3.737 4.364 19.129Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9686 .5804 .3765)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m374.54 809.188 6.862 27.272 15.718-14.149-2.855-10.032-19.725-3.091Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .8078 .349 .2706)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m394.627 787.335 6.863 27.272-4.371 7.704-2.855-10.032.363-24.944Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .8118 .4157 .3922)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m428.14 823.82-26.65-9.214-4.371 7.705 10.933 3.737 20.088-2.228Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9843 .6902 .251)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m419.997 801.501-25.369-14.166 6.862 27.272 26.65 9.214-8.143-22.32Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9686 .5804 .3765)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m415.419 805.152-7.258 6.118 10.852 3.746-3.594-9.864Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .8078 .349 .2706)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m404.201 798.884 11.219 6.268-7.258 6.117-.927-.321-3.034-12.064Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-5">
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m463.883 807.204 34.07-11.451.545 24.196-34.615-12.745Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .8353 .502 .4)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m479.759 830.577-15.876-23.374 34.615 12.745-18.739 10.629Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-6">
          <g class="icon-Group">
            <path
              fill="color(display-p3 .9843 .6902 .251)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m570.67 785.867 15.335 9.441 1.616 8.544-.044.027-15.291-9.468-1.616-8.544Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9098 .3961 .2902)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m587.575 803.879-7.481 5.128-15.344-9.45 7.534-5.146 15.291 9.468Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .5804 .3765)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m572.285 794.411-7.535 5.146-1.607-8.535 7.526-5.155 1.616 8.544Z"
              class="icon-Vector"
            />
          </g>
          <g class="icon-Group">
            <path
              fill="color(display-p3 .9843 .6902 .251)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m564.77 799.557-1.563-8.58-7.378-4.582-.044.027 1.607 8.535 7.378 4.6Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9098 .3961 .2902)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m555.783 786.422-14.33 9.802 1.572 8.571 14.365-9.838-1.607-8.535Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .5804 .3765)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m557.39 794.957-14.365 9.838 7.387 4.591 14.356-9.83-7.378-4.599Z"
              class="icon-Vector"
            />
          </g>
          <g class="icon-Group">
            <path
              fill="color(display-p3 .9843 .6902 .251)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m575.563 812.114-3.259-17.721-7.379-4.582-.044.027 3.294 17.685 7.388 4.591Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9098 .3961 .2902)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m564.882 789.838-7.49 5.119 3.268 17.712 7.516-5.146-3.294-17.685Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .5804 .3765)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m568.176 807.523-7.517 5.146 7.378 4.582 7.526-5.137-7.387-4.591Z"
              class="icon-Vector"
            />
          </g>
          <g class="icon-Group">
            <path
              fill="color(display-p3 .9843 .6902 .251)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m570.724 785.832-3.251-17.711-7.387-4.591-.035.036 3.294 17.684 7.379 4.582Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9098 .3961 .2902)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m560.05 763.566-7.49 5.119 3.268 17.712 7.517-5.146-3.295-17.685Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .5804 .3765)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m563.346 781.25-7.516 5.146 7.378 4.582 7.517-5.146-7.379-4.582Z"
              class="icon-Vector"
            />
          </g>
          <g class="icon-Group">
            <path
              fill="color(display-p3 .9843 .6902 .251)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m586.643 784.582-1.572-8.571-7.369-4.591-.054.036 1.607 8.535 7.388 4.591Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9098 .3961 .2902)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m577.647 771.456-14.312 9.803 1.563 8.562 14.356-9.829-1.607-8.536Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .5804 .3765)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m579.255 779.991-14.356 9.829 7.378 4.6 14.365-9.838-7.387-4.591Z"
              class="icon-Vector"
            />
          </g>
          <g class="icon-Group">
            <path
              fill="color(display-p3 .9843 .6902 .251)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m547.973 771.826 15.335 9.441 1.617 8.544-.045.027-15.309-9.468-1.598-8.544Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9098 .3961 .2902)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m564.879 789.838-7.49 5.119-15.335-9.441 7.516-5.146 15.309 9.468Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9686 .5804 .3765)"
              stroke="color(display-p3 .1373 .1216 .1255)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m549.57 780.37-7.517 5.146-1.607-8.535 7.526-5.155 1.598 8.544Z"
              class="icon-Vector"
            />
          </g>
        </g>
        <g class="icon-7">
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="m650.417 756.69 7.119 2.168-23.954 23.954-7.208-2.079 24.043-24.043Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m650.417 756.69 7.118 2.168-22.847-34.744-7.199-2.088 22.928 34.664Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .8353 .502 .4)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="m626.374 780.733-22.928-34.664 24.043-24.043 22.928 34.664-24.043 24.043Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-8">
          <path
            fill="color(display-p3 .8353 .502 .4)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m712.808 682.908-29.952 38.953 23.11 3.395 6.842-42.348Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m666.274 693.935 16.582 27.927 29.952-38.954-46.534 11.027Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-9">
          <path
            fill="color(display-p3 .4549 .4196 .6)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m722.52 630.681 2.149-12.88 9.436-.231-11.585 13.111Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .702 .6392 .8078)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m734.105 617.57 25.968 24.062 11.219-4.652-25.955-24.074-11.232 4.664Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5569 .5098 .6941)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m748.487 654.743 11.586-13.111-25.968-24.062-11.586 13.11 25.968 24.063Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .251 .2118 .4784)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m748.132 663.202-25.968-24.062.354-8.459 25.968 24.062-.354 8.459Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .702 .6392 .8078)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m769.333 659.711-20.847-4.968 11.586-13.11 9.261 18.078Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7765 .7294 .8627)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m771.292 636.98-1.958 22.731-9.261-18.078 11.219-4.653Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5569 .5098 .6941)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m748.133 663.202.354-8.459 20.848 4.968-21.202 3.491Zm-2.795-50.296-11.233 4.664-9.436.231 20.669-4.895Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-10">
          <path
            fill="color(display-p3 .9451 .7137 .7804)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m757.177 577.411 59.405 9.618 4.876-30.271-59.405-9.618c-3.707-.6-7.809 5.691-9.155 14.048-1.346 8.357.572 15.623 4.279 16.223Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7529 .4039 .4941)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M812.303 570.805c-1.346 8.359.569 15.622 4.279 16.223 3.71.601 7.808-5.689 9.155-14.048 1.346-8.359-.569-15.622-4.279-16.223-3.709-.6-7.808 5.689-9.155 14.048Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-11">
          <path
            fill="color(display-p3 .7216 .3451 .4314)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m818.915 482.474-3.514-10.335-14.362-3.213 3.514 10.335 14.362 3.213Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7765 .3529 .3373)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m797.067 491.918-3.515-10.336 7.487-12.656 3.514 10.336-7.486 12.656Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9843 .6902 .251)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m827.711 493.249-2.8-29.497-28.92-6.48-15.072 25.499 19.6 22.235 27.192-11.757Zm-34.16-11.667 7.487-12.656 14.362 3.213 1.387 14.656-13.503 5.838-9.733-11.051Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9686 .5804 .3765)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m831.216 503.593-3.505-10.344-2.801-29.498 3.514 10.336 2.792 29.506Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9294 .5059 .4157)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m831.216 503.593-3.506-10.344-27.193 11.757 3.515 10.335 27.184-11.748Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .4471 .3882)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m784.433 493.106-3.514-10.335 19.599 22.235 3.514 10.335-19.599-22.235Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-12">
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m844.27 407.094-59.477 5.025 4.143-19.81 43.157-2.991 12.177 17.776Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .8275 .4039 .3765)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m842.203 414.729-47.468 10.094-5.155 1.084-4.787-13.788 59.477-5.024-2.067 7.634Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-13">
          <path
            fill="color(display-p3 .9176 .5765 .4471)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m806.362 310.936 16.451 15.365-37.932-.015-13.433-17.369 34.914 2.019Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .4667 .2196 .3059)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m789.019 338.277-16.098-22.046s-1.658-3.119-1.473-7.315l13.433 17.368s2.043 7.565 4.138 11.993Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9451 .7137 .7804)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M822.813 326.3c.004 10.477-8.482 18.963-18.958 18.959-10.477-.005-18.97-8.498-18.975-18.974l37.933.015Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-14">
          <path
            fill="color(display-p3 .7412 .3333 .4235)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m774.96 229.401-34.381 34.381 30.918 13.572 34.382-34.381-30.919-13.572Zm-3.654 36.808-15.281-6.554 19.127-19.127 15.281 6.554-19.127 19.127Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m805.932 243.026-5.029-9.873-30.972-13.625 5.029 9.873 30.972 13.625Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m740.578 263.783-5.029-9.873 34.382-34.382 5.028 9.873-34.381 34.382Zm30.691 2.462-5.02-9.864 13.758-13.758 10.425 4.458-19.163 19.164Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m756.023 259.673 15.246 6.572-5.02-9.864-4.624-2.309-5.602 5.601Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-15">
          <path
            fill="color(display-p3 .2471 .2431 .5098)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M733.606 183.506c-2.351 2.35-5.576 2.581-7.821.798l.178-.178c2.787-2.787 3.32-7.491 1.162-10.503a6.297 6.297 0 0 0-1.149-1.221c2.787-2.609 6.662-2.367 8.784.592 2.157 3.012 1.624 7.715-1.163 10.503l.009.009Zm6.181 17.703-7.242.594-.365.027c-2.192.106-4.678-1.098-6.861-3.299 1.728-.908 3.402-2.404 4.773-4.417 1.587 2.745 3.601 4.794 5.668 5.971 1.346.776 2.736 1.168 4.019 1.115l.008.009Z"
            class="icon-Vector"
          />
          <path
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m740.154 201.182-.366.027"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .2471 .2431 .5098)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m721.933 199.562-7.421.612-.329.026c-1.746.089-3.386-.482-4.696-1.774-3.422-3.423-3.193-10.585.501-16.027l6.076-.496 1.55-.124c-.338.499-.641.997-.908 1.514-2.795 5.201-2.704 11.402.416 14.503.392.392.811.722 1.256.972 1.069.624 2.272.883 3.537.794h.018Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5176 .4863 .6745)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m717.643 181.769-1.55.124-6.076.497a7.17 7.17 0 0 1-1.087-.446c-5.72-2.871-9.17-9.046-7.702-13.812.898-2.912 3.436-4.631 6.581-4.852l.169-.009 7.136-.594a8.022 8.022 0 0 0-3.287 1.059c-1.407.819-2.449 2.092-2.965 3.749-1.406 4.561 1.679 10.424 6.963 13.428.232.143.481.268.731.393.329.169.704.312 1.087.445v.018Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .6588 .6039 .7804)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m730.182 151.139-7.224.594s-6.067-.047-7.31 10.928c0 0 3.759-.373 7.618 2.257-.402-2.343.494-12.401 6.925-13.77l-.009-.009Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7294 .6902 .8431)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M742.943 184.24s.134-.027.187-.045c5.844-1.601 9.592-7.007 8.387-12.059-1.204-5.016-6.845-7.806-12.644-6.285.027-.277.045-.544.054-.784.184-7.225-3.178-13.471-7.525-13.937-4.348-.465-8.025 4.995-8.218 12.228-.008.473.01 1.007.055 1.568a10.964 10.964 0 0 0-1.355-.838c-5.729-2.862-11.564-1.323-13.032 3.443-1.468 4.765 1.974 10.949 7.703 13.811.33.169.686.312 1.087.446-3.704 5.45-3.932 12.613-.501 16.026 3.431 3.414 9.23 1.768 12.951-3.665 3.442 5.971 8.957 8.708 12.484 6.108 3.58-2.635 3.737-9.78.367-16v-.017Zm-9.336-.735c-2.787 2.787-6.805 2.599-8.962-.413s-1.634-7.725 1.162-10.503c2.788-2.787 6.805-2.599 8.963.413 2.157 3.012 1.633 7.725-1.163 10.503Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-16">
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m667.075 140.59-8.081-3.379 8.43-15.293 5.346 1.998-5.695 16.674Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7412 .3333 .4235)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M641.427 119.644c.249-.962 1.388-3.153 1.388-3.153s7.738-10.001 9.883-13.217l-1.059 2.842s-9.322 12.192-10.212 13.528Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9176 .5765 .4471)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m658.995 137.21-17.567-17.566 10.195-13.528 15.802 15.802-8.43 15.292Zm31.793 1.75c.347-1.684 2.666-16.48 2.666-16.48s.8-2.53 1.059-2.841c-.845 4.249-2.488 15.357-2.488 15.357s-.747 2.797-1.255 3.964h.018Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9059 .7059 .5216)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m690.788 138.96-23.714 1.63 5.694-16.675 20.685-1.434-2.665 16.479Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9451 .8118 .7059)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M693.455 122.48c-10.77.842-20.694 1.426-20.694 1.426l-5.346-1.998s-7.041-7.255-15.793-15.793l1.059-2.841c10.619-.833 20.249-1.39 20.249-1.39l5.8 1.971s6.969 7.183 15.793 15.793l-1.059 2.841-.009-.009Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-17">
          <path
            fill="color(display-p3 .9451 .7137 .7804)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m597.991 53.633-14.955 37.511 24.953 6.54 4.578-40.27c.567-2.704-1.238-5.953-6.049-7.18-4.495-.997-7.457.712-8.422 3.1l-.019.074c-.02.073-.051.143-.071.216l-.015.009Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7529 .4039 .4941)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M593.143 104.368c-16.708-4.249-12.39-22.535 4.424-18.78 16.708 4.249 12.39 22.535-4.424 18.78Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-18">
          <path
            fill="color(display-p3 .9529 .4471 .3882)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="M509.168 68.274c3.224 3.224 8.449 3.227 11.671.005 3.221-3.221 3.219-8.446-.005-11.67-3.224-3.224-8.449-3.226-11.671-.005-3.221 3.221-3.219 8.446.005 11.67Z"
            class="icon-Vector"
          />
          <g class="icon-Group">
            <g class="icon-Group">
              <path
                fill="color(display-p3 .4314 .2157 .3059)"
                stroke="color(display-p3 .1373 .1216 .1255)"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="m509.317 57.629-3.383-15.654 9.077-1.947 3.383 15.654c.41 1.888-1.291 3.856-3.794 4.399-2.503.542-4.864-.555-5.274-2.443l-.009-.01Z"
                class="icon-Vector"
              />
              <path
                fill="color(display-p3 .7255 .4039 .4941)"
                stroke="color(display-p3 .1373 .1216 .1255)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M511.211 44.42c2.503-.538 4.201-2.507 3.793-4.398-.409-1.892-2.769-2.988-5.271-2.45-2.503.538-4.201 2.508-3.793 4.399.408 1.891 2.768 2.988 5.271 2.45Z"
                class="icon-Vector"
              />
            </g>
            <g class="icon-Group">
              <path
                fill="color(display-p3 .7216 .3451 .4314)"
                stroke="color(display-p3 .1373 .1216 .1255)"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="m519.814 68.128 15.653 3.383 1.947-9.077-15.653-3.383c-1.889-.41-3.857 1.29-4.4 3.793-.542 2.503.555 4.865 2.444 5.275l.009.01Z"
                class="icon-Vector"
              />
              <path
                fill="color(display-p3 .7255 .4039 .4941)"
                stroke="color(display-p3 .1373 .1216 .1255)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M535.464 71.509c1.891.408 3.861-1.29 4.399-3.793.538-2.503-.558-4.862-2.45-5.27-1.891-.409-3.86 1.289-4.398 3.792-.539 2.503.558 4.863 2.449 5.271Z"
                class="icon-Vector"
              />
            </g>
          </g>
          <path
            fill="color(display-p3 .9686 .5804 .3765)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M506.21 45.744c3.224 3.224 8.449 3.226 11.671.005 3.221-3.221 3.219-8.446-.005-11.67-3.224-3.224-8.449-3.226-11.671-.005-3.221 3.221-3.219 8.446.005 11.67Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .4471 .3882)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="M531.174 70.707c3.224 3.224 8.449 3.226 11.67.005 3.221-3.222 3.219-8.446-.005-11.67-3.224-3.224-8.449-3.227-11.67-.005-3.221 3.221-3.219 8.446.005 11.67Z"
            class="icon-Vector"
          />
          <g class="icon-Group">
            <g class="icon-Group">
              <path
                fill="color(display-p3 .8078 .349 .2706)"
                stroke="color(display-p3 .1373 .1216 .1255)"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="m532.231 62.218-3.383-15.654 9.077-1.947 3.383 15.653c.411 1.89-1.29 3.857-3.793 4.4-2.503.542-4.864-.555-5.275-2.444l-.009-.008Z"
                class="icon-Vector"
              />
              <path
                fill="color(display-p3 .7255 .4039 .4941)"
                stroke="color(display-p3 .1373 .1216 .1255)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M534.132 49.006c2.503-.538 4.201-2.507 3.792-4.398-.408-1.891-2.768-2.988-5.271-2.45-2.502.538-4.2 2.508-3.792 4.399.408 1.891 2.768 2.988 5.271 2.45Z"
                class="icon-Vector"
              />
            </g>
            <g class="icon-Group">
              <path
                fill="color(display-p3 .9529 .4471 .3882)"
                stroke="color(display-p3 .1373 .1216 .1255)"
                strokeMiterlimit="10"
                strokeWidth=".5"
                d="m515.236 45.22 15.654 3.384 1.947-9.077-15.653-3.383c-1.889-.41-3.857 1.29-4.4 3.793-.542 2.503.555 4.864 2.444 5.275l.008.009Z"
                class="icon-Vector"
              />
              <path
                fill="color(display-p3 .7255 .4039 .4941)"
                stroke="color(display-p3 .1373 .1216 .1255)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M530.885 48.594c1.891.408 3.86-1.29 4.399-3.793.538-2.503-.559-4.863-2.45-5.27-1.891-.409-3.86 1.289-4.399 3.792-.538 2.503.559 4.863 2.45 5.27Z"
                class="icon-Vector"
              />
            </g>
          </g>
          <path
            fill="color(display-p3 .9686 .5804 .3765)"
            stroke="color(display-p3 .1373 .1216 .1255)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M528.874 48.568c3.224 3.224 8.449 3.227 11.671.005 3.221-3.221 3.219-8.446-.005-11.67-3.224-3.224-8.449-3.226-11.671-.005-3.221 3.221-3.219 8.446.005 11.67Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-19">
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m435.067 54.818 12.026.005-.013-30.664-12.026-.005c-5.239-.002-9.494 6.856-9.499 15.328-.006 8.472 4.255 15.333 9.512 15.336Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M447.08 54.83c5.246.002 9.496-6.86 9.492-15.328-.003-8.468-4.259-15.335-9.505-15.337-5.247-.002-9.497 6.86-9.493 15.329.003 8.468 4.259 15.334 9.506 15.336Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-20">
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m362.796 10.746-32.502 45.857 40.624 15.348-8.122-61.205Zm-5.647 21.594 3.378 25.451-16.893-6.385 13.515-19.066Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m362.796 10.746 11.578-2.175 8.122 61.206-11.582 2.187-8.118-61.218Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7922 .3333 .3098)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m343.624 51.443 11.581-2.187 3.423-5.772-1.48-11.143-13.524 19.102Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m360.524 57.804-.911-6.883-4.407-1.665-11.582 2.187 16.9 6.36Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-21">
          <path
            fill="color(display-p3 .898 .3608 .251)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="M302.271 80.688c-10.517 10.517-27.568 10.51-38.084-.007-10.517-10.516-10.524-27.567-.007-38.084a26.66 26.66 0 0 1 7.312-5.19c10.02-4.852 22.439-3.118 30.763 5.206a26.149 26.149 0 0 1 1.881 2.094c8.601 10.578 7.975 26.141-1.865 35.981Z"
            class="icon-Vector"
          />
          <path
            stroke="color(display-p3 .1137 .1137 .1059)"
            stroke-dasharray="10 10"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="M264.187 80.681s-7.212-13.018 8.042-28.272c4.069-4.07 9.707-7.515 15.39-9.383m-1.355 41.62c3.563-1.21 7.321-3.4 11.025-7.104 11.862-11.861 10.975-23.095 7.301-32.255m-11.265 10.231s-6.74 8.718-14.452 16.305"
            class="icon-Vector"
          />
        </g>
        <g class="icon-22">
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m222.941 80.877-38.899 11.556 14.658 7.855 33.223-14.026-8.982-5.385Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m202.058 119.87 29.938-29.938-.073-3.67-33.223 14.026 3.358 19.582Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7922 .3333 .3098)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m184.007 92.469 3.313 19.199 14.693 8.247-3.349-19.591-14.657-7.855Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-23">
          <path
            fill="color(display-p3 .702 .6392 .8078)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m172.622 155.742-.789-10.994-15.57-16.603-14.773-5.636.789 10.975 15.57 16.621 14.773 5.637Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9451 .7137 .7804)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m138.614 169.34 19.234-19.235 14.773 5.637-19.234 19.234-14.773-5.636Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5569 .5098 .6941)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m142.279 133.484 15.57 16.621-19.234 19.235-15.579-16.612 19.243-19.244Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .251 .2118 .4784)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m141.491 122.509-19.244 19.243.789 10.976 19.243-19.244-.788-10.975Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-24">
          <g class="icon-Group">
            <path
              fill="color(display-p3 .7529 .4039 .4941)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m71.399 210.424 21.267 6.574 11.327-12.895-32.594 6.321Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9451 .7137 .7804)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m103.993 204.104-11.327 12.894 5.361 16.296 5.966-29.19Z"
              class="icon-Vector"
            />
          </g>
          <path
            fill="color(display-p3 .4667 .2196 .3059)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M97.822 233.5 71.4 210.425l21.267 6.574 5.156 16.501Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-25">
          <g class="icon-Group">
            <path
              fill="color(display-p3 .9529 .5843 .3569)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m82.3 293.386-.788-10.976-15.561-16.612-14.773-5.636.789 10.976 15.56 16.612 14.773 5.636Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .8275 .4039 .3765)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m63.522 294.893 4.006-7.143 14.772 5.636-3.997 7.134-14.781-5.627Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .9294 .4235 .3686)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeMiterlimit="10"
              strokeWidth=".5"
              d="m51.967 271.138 15.561 16.612-4.006 7.143-18.27-19.802 6.715-3.953Z"
              class="icon-Vector"
            />
            <path
              fill="color(display-p3 .8118 .3176 .2353)"
              stroke="color(display-p3 .1137 .1137 .1059)"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="m51.179 260.162-6.724 3.943.797 10.985 6.715-3.953-.788-10.975Z"
              class="icon-Vector"
            />
          </g>
          <path
            fill="color(display-p3 .7412 .3333 .4235)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m63.524 294.893-24.317 7.402 6.046-27.204 18.271 19.802Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5922 .2784 .2824)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m39.206 302.295 39.098-1.774-14.781-5.628-24.318 7.402Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .3333 .2353 .2784)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m44.455 264.105.797 10.985-6.046 27.204 5.249-38.189Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-26">
          <path
            fill="#fff"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m24 371.932 3.824-19.309-16.81 9.093L24 371.932Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m56.997 386.227 4.198-20.109-17.191 9.898 12.993 10.211Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7922 .3333 .3098)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m44.005 376.016-32.993-14.3L24 371.932l32.999 14.295-12.993-10.211Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m61.196 366.118-33.373-13.495-16.81 9.093 32.992 14.3 17.19-9.898Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-27">
          <path
            fill="color(display-p3 .902 .7333 .5412)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m32.05 424.257 12.083 6.723 8.253 9.553-14.299-1.298-6.037-14.978Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9294 .4235 .3686)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeMiterlimit="10"
            strokeWidth=".5"
            d="m20.057 438.96 2.374-10.235 9.62-4.468 6.037 14.978-3.044 8.337-14.987-8.612Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m35.044 447.573 9.958 16.334 10.516-11.853-3.132-11.52-14.299-1.298-3.043 8.337Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .8118 .3176 .2353)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m20.056 438.961-9.006 1.145 11.38-11.38-2.374 10.235Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .4667 .2196 .3059)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m35.045 447.573-9.216 10.803-8.714-3.496 2.942-15.919 14.988 8.612Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7412 .3333 .4235)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m45.002 463.907-16.008 2.853-3.166-8.385 9.216-10.802 9.958 16.334Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .3333 .2353 .2784)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m11.05 440.106 1.727 17.649 4.337-2.876 2.942-15.918-9.006 1.145Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5922 .2784 .2824)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m28.995 466.76-3.166-8.384-8.714-3.496-4.337 2.876 7.94 6.961 8.277 2.043Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .8275 .4039 .3765)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m45.003 463.906-4.29 9.353-12.33-.879.612-5.621 16.008-2.853Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .902 .7333 .5412)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m55.518 452.054-14.806 21.205 4.29-9.352 10.516-11.853Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .5255 .4275 .2863)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m28.382 472.38-7.665-7.665 8.277 2.044-.612 5.621Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .4667 .2196 .3059)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m12.777 457.754 2.105 5.827 5.836 1.134-7.94-6.961Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7412 .3333 .4235)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m28.382 472.381-9.409-3.514-4.091-5.285 5.836 1.134 7.664 7.665Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-28">
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M32.99 544.759c.9-.436 36.206-16.172 36.206-16.172l-7.285-15.477-40.186 21.364 11.256 10.294.009-.009Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .7922 .3333 .3098)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m40.476 508.932-18.751 25.524 40.195-21.355-21.444-4.169Z"
            class="icon-Vector"
          />
        </g>
        <g class="icon-29">
          <path
            fill="color(display-p3 .9765 .698 .2)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m93.207 608.189-6.839-17.871-17.77-6.916-.09.089 6.917 17.771 17.782 6.927Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .898 .3608 .251)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M68.509 583.49 54.44 597.559l6.828 17.86 14.157-14.158-6.916-17.771Z"
            class="icon-Vector"
          />
          <path
            fill="color(display-p3 .9529 .5843 .3569)"
            stroke="color(display-p3 .1137 .1137 .1059)"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="m75.425 601.261-14.157 14.157 17.793 6.917 14.146-14.147-17.782-6.927Z"
            class="icon-Vector"
          />
        </g>
      </g>
    </Box>
  );
};
