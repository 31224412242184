// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const primitive = {
  spaceCadet: '#22223B',
  lightOrange: '#F9DDBD',
  seaShell: '#FFF4EC',
  richBlack: '#0E0E1B'
};

const texts = {
  primaryInvert: primitive.seaShell,
  primaryDark: primitive.richBlack
};

const base = {
  primary: primitive.seaShell, //not defined in the design
  secondary: primitive.lightOrange, //not defined in the design
  background: primitive.spaceCadet,
  text: texts.primaryInvert,
  heading: texts.primaryInvert,
  backgroundDarker: '#171730',
  hoverLink: '#F79460'
};

export const colors = {
  // defaults
  ...base,
  texts,
  primitive
};
