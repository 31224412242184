import React from 'react';
import { DynamicButton } from './DynamicButton';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const PrimaryButton = ({
  className,
  children,
  variant = 'links.primary',
  sx,
  lineSx,
  ...props
}) => {
  return (
    <DynamicButton
      className={className}
      variant={variant}
      sx={{
        position: 'relative',
        '&:hover': {
          '& .__button-content': {
            transform: 'translateY(-3px)'
          },
          '& .__button-line': {
            transform: 'scaleY(2)'
          }
        },
        color: 'text',
        ...sx
      }}
      {...props}>
      <Box
        as="span"
        className="__button-content"
        sx={{
          display: 'block', //? display block to enable transform value
          transition: 'transform 0.3s ease'
        }}>
        {children}
      </Box>
      <ButtonLine
        sx={{
          // animation value
          transition: 'transform 0.3s ease',
          transformOrigin: 'top',
          ...lineSx
        }}
      />
    </DynamicButton>
  );
};

export const ButtonLine = ({ className = '', sx, ...props }) => {
  return (
    <Box
      className={`__button-line ${className}`}
      as="hr"
      sx={{
        // display: 'block',
        border: 0,
        width: '100%',
        height: '2px',
        // bg: 'texts.primaryInvert',
        bg: 'currentColor',
        position: 'absolute',
        // bottom: 0,
        top: '100%',
        ...sx
      }}
      {...props}
    />
  );
};
