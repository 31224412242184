import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { AspectRatioBox } from './AspectRatioBox';

export const EmbeddedVideo = ({ src, sx, aspectRatio, ...props }) => {
  return (
    <AspectRatioBox
      sx={{
        overflow: 'hidden',
        ...sx
      }}
      aspectRatio={aspectRatio}
      {...props}>
      <Vimeo
        video={src}
        controls={false}
        muted={true}
        autoplay={true}
        loop={true}
        responsive={true}
        dnt={true}
        pip={false}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      />
    </AspectRatioBox>
  );
};
