import React from 'react';

// External Components
import { MotionBox } from 'components/MotionElement';

export const AboutLinkArrow = ({ sx, ...props }) => {
  return (
    <MotionBox
      as="svg"
      className="about-link-arrow"
      viewBox="0 0 18 13"
      fill="none"
      sx={sx}
      {...props}>
      <path
        d="M7.759 9.0518L6.31158 9.53689L2.57422 5.84666L4.02163 5.36157L7.759 9.0518Z"
        fill="#6E374E"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.759 2.92993L6.31158 3.41306L2.57422 5.84833L4.02163 5.36324L7.759 2.92993Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.97476 8.28418L7.52734 9.18562V12.3731L8.97476 11.888V8.28418Z"
        fill="#9C5374"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.631 4.66577L16.1836 5.15086V11.1173L17.631 10.6322V4.66577Z"
        fill="#9C5374"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6298 4.66481L16.1824 5.1499L7.52734 4.17186L8.97476 3.68677L17.6298 4.66481Z"
        fill="#6E374E"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1165 8.95414L14.669 9.43923L6.30859 7.74632L7.75601 7.26123L16.1165 8.95414Z"
        fill="#6E374E"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.97476 0.502686L7.52734 0.987777V4.17327L8.97476 3.68818V0.502686Z"
        fill="#9C5374"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.52753 12.3739V9.18844L16.1826 11.119V5.15256L7.52753 4.17452V0.987061L0.394531 5.4825L7.52753 12.3739ZM6.30989 7.74691V9.53802L2.57253 5.84779L6.30989 3.41252V5.20362L14.6703 6.31913V9.43982L6.30989 7.74691Z"
        fill="#B9677E"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.97476 9.84493L7.52734 10.5598V9.18701L8.97476 9.51106V9.84493Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.92969 11.8483L8.65241 11.6067"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5469 5.70421L17.2696 5.46265"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5469 6.25726L16.9082 6.1355"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.19922 11.3312L8.56058 11.2095"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </MotionBox>
  );
};
