import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const FooterIllustrationTwo = ({ sx, ...props }) => {
  return (
    <Box
      className="footer-illustration-two"
      as="svg"
      viewBox="0 0 35 35"
      fill="none"
      sx={sx}
      {...props}>
      <path
        d="M34.3341 23.9408L16.6385 33.8719L17.3371 20.5697L23.8708 17.3651L34.3341 23.9408Z"
        fill="#F37263"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M1.43604 24.7814L16.6388 33.8717L17.3374 20.5695L11.5977 17.3728L1.43604 24.7814Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M0.649536 6.10153L15.8523 15.1918L17.3369 20.5698L11.5971 17.3731L0.649536 6.10153Z"
        fill="#CE5945"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M31.8253 7.4259L15.8524 15.1915L17.337 20.5695L23.8706 17.3649L31.8253 7.4259Z"
        fill="#CF6A64"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M18.258 1.11182L0.64978 6.10114L15.8526 15.1914L31.8254 7.42581L18.258 1.11182Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M17.8451 4.77725L17.337 10.7343L23.8389 7.56937L17.8451 4.77725Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.0575 6.98151L17.8455 4.77637L17.3374 10.7334L16.7817 11.0031L10.0575 6.98151Z"
        fill="#CE5945"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </Box>
  );
};
