const linkDefault = {
  cursor: 'pointer'
};

const buttonDefault = {
  cursor: 'pointer'
};

const hyperlinkDefault = {
  // textDecoration: 'underline 2px'
  //? text Decoration is partially supported for the browser, https://caniuse.com/?search=text%20decoration%20line
  textDecorationLine: 'underline',
  textDecorationThickness: '2px',
  textUnderlineOffset: '6px',
  ...linkDefault
};

const links = {
  primary: {
    ...linkDefault,
    fontSize: ['2rem', '2rem', '2rem', '2rem'],
    lineHeight: 1,
    fontFamily: 'body.bold',
    display: 'inline-block',
    width: 'max-content',
    whiteSpace: 'nowrap',
    // for button rest
    px: 0,
    py: '0.6rem',
    bg: 'transparent'
  },
  hyperlink: {
    // small
    ...hyperlinkDefault,
    // for button reset
    p: 0,
    bg: 'transparent'
  },
  footer: {
    ...linkDefault
  },
  legal: {
    ...linkDefault
  },
  contact: {
    ...linkDefault
  },
  clear: {
    ...linkDefault,
    p: 0,
    m: 0,
    display: 'block',
    width: '100%',
    '& a': {
      display: 'block'
    }
  },
  navigation: {
    ...linkDefault,
    fontFamily: 'body.medium',
    lineHeight: 1,
    fontSize: ['1.4rem', '1.4rem', '1.4rem', '1.4rem'],
    letterSpacing: '0.03em',
    width: 'max-content',
    color: 'texts.primaryInvert',
    transition: 'unset',
    // button reset,
    p: 0
  },
  animatedNavigation: {
    variant: 'links.navigation',
    // animation value
    py: '0.4rem', //the space plus the height of the line
    bg: 'transparent'
  }
};

const buttons = {
  primary: {
    ...buttonDefault
  },
  clear: {
    ...buttonDefault,
    p: 0,
    bg: 'transparent'
  }
};

const cards = {};

export { links, buttons, cards };
