import { useRevertPathToDefaultLocale } from 'gatsby-theme-thepuzzlers-intl';
import { removeSlash } from './removeSlash';
import { useLocation } from '@reach/router';

export const usePathContain = (pathContains = []) => {
  const { pathname } = useLocation();
  const pageName = useRevertPathToDefaultLocale(pathname);

  const isOneOfPathMatch = pathContains.some((path) => {
    return pageName.includes(path);
  });

  return isOneOfPathMatch;
};
