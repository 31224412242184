import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const NavIllustrationTwo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="nav-illustration-two"
      viewBox="0 0 43 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M7.9867 33.5214L15.3322 39.0255C11.4482 36.1678 13.5109 26.7595 19.9408 18.0153C26.3707 9.261 34.7325 4.48138 38.6166 7.33909L31.271 1.83497C27.3869 -1.02274 19.0251 3.76695 12.5953 12.5111C6.16541 21.2654 4.10262 30.6737 7.9867 33.5214Z"
        fill="#C65A56"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.4377 18.1461L9.68707 5.97058L3.48865 21.6478L34.2392 33.8232C36.1612 34.588 39.1094 31.69 40.82 27.3632C42.5307 23.0364 42.3596 18.9108 40.4477 18.1461H40.4377Z"
        fill="#B8586E"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.0639 15.1776C11.7775 10.8473 11.6084 6.72027 9.6861 5.95957C7.76382 5.19888 4.81636 8.0926 3.10276 12.4229C1.38915 16.7532 1.55831 20.8802 3.48058 21.6409C5.40285 22.4016 8.35031 19.5079 10.0639 15.1776Z"
        fill="#6E374E"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M38.6164 7.32907L31.2709 1.82495C35.155 4.68266 33.0922 14.091 26.6623 22.8352C20.2325 31.5894 11.8706 36.3691 7.98657 33.5114L15.3321 39.0155C19.2162 41.8732 27.578 37.0835 34.0079 28.3393C40.4377 19.585 42.5005 10.1767 38.6164 7.32907Z"
        fill="#F37263"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2709 1.82485C28.0408 -0.549871 21.7015 2.36822 15.966 8.45595L20.4639 10.237C22.899 8.29495 25.1731 7.51009 26.4208 8.42576C28.4333 9.90493 27.0245 15.248 23.2713 20.3497C19.518 25.4614 14.8491 28.3996 12.8265 26.9204C12.2932 26.528 12.0014 25.8538 11.931 24.9884L7.01048 23.0363C5.46088 27.826 5.67219 31.8208 7.97648 33.5113C11.8606 36.369 20.2224 31.5793 26.6522 22.8351C33.0821 14.0808 35.1449 4.6725 31.2608 1.81479L31.2709 1.82485Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  );
};
