import React from 'react';

// ExternalComponents
import { Button, Link } from '@thepuzzlers/pieces';

import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

export const DynamicButton = ({ children, variant, sx, to, ...props }) => {
  if (!to)
    return (
      <Button sx={sx} variant={variant} {...props}>
        {children}
      </Button>
    );

  const isExternalLink =
    to.startsWith('https://') ||
    to.startsWith('mailto:') ||
    to.startsWith('tel:');

  if (isExternalLink)
    return (
      <Link variant={variant} sx={sx} href={to} {...props}>
        {children}
      </Link>
    );

  return (
    <NavigationLink
      sx={{
        variant,
        // style reset to allow p handle by a instead
        py: 0,
        px: 0,
        ...sx,
        '& a': {
          variant,
          color: 'inherit',
          display: 'inline-block',
          position: 'relative'
        }
      }}
      to={to}
      {...props}>
      {children}
    </NavigationLink>
  );
};
