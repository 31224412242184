import React from 'react';
import { usePathMatch } from '../helper';
import { useAnimation } from 'framer-motion';

export const useAnimationWhenPathUnMatch = ({ to, animate }) => {
  const { isPathMatch, pathname } = usePathMatch(to);

  const controls = useAnimation();

  React.useEffect(() => {
    controls.start({
      ...animate
    });
    if (isPathMatch) {
      controls.stop();
    }
  }, [isPathMatch]);

  return controls;
};
