import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ThePuzzlersLogo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="thepuzzlers-logo"
      viewBox="0 0 156 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'texts.primaryInvert',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M10.3863 16.643V19.6432C10.3863 19.7146 10.3863 19.7146 10.3116 19.7146C9.48966 19.9289 9.11605 20.0003 8.06995 20.0003C4.48331 20.0003 2.24165 18.3574 2.24165 14.7145V8.92863C2.24165 8.8572 2.16692 8.85721 2.16692 8.85721H0.0747262C4.55953e-06 8.85721 0 8.78572 0 8.78572V5.71427C0 5.64284 0.0747262 5.64285 0.0747262 5.64285H2.16692C2.24164 5.64285 2.24165 5.57135 2.24165 5.57135V2.85706C2.24165 2.78563 2.31637 2.78564 2.31637 2.78564H6.12718C6.2019 2.78564 6.20191 2.85706 6.20191 2.85706V5.57135C6.20191 5.64278 6.27661 5.64285 6.27661 5.64285H10.2368C10.3116 5.64285 10.3116 5.71427 10.3116 5.71427V8.78572C10.3116 8.85715 10.2368 8.85721 10.2368 8.85721H6.27661C6.20189 8.85721 6.20191 8.92863 6.20191 8.92863V14.3573C6.20191 16.0002 7.09855 16.5716 8.89187 16.5716C9.3402 16.5716 9.48963 16.5716 10.2368 16.5002C10.3116 16.5716 10.3863 16.643 10.3863 16.643Z"
        fill="currentColor"
      />
      <path
        d="M26.154 11.0003V19.5719C26.154 19.6433 26.0793 19.7147 26.0046 19.7147H22.2685C22.1938 19.7147 22.119 19.6433 22.119 19.5719V11.5002C22.119 9.85735 20.9982 8.78586 19.4291 8.78586C17.7852 8.78586 16.6644 9.85735 16.6644 11.5002V19.5719C16.6644 19.6433 16.5896 19.7147 16.5149 19.7147H12.7788C12.7041 19.7147 12.6294 19.6433 12.6294 19.5719V0.142826C12.6294 0.0713953 12.7041 0 12.7788 0H16.5149C16.5896 0 16.6644 0.0713953 16.6644 0.142826V6.64296C16.6644 6.71439 16.8138 6.78589 16.8885 6.71446C17.8599 5.8573 19.2049 5.35727 20.6993 5.35727C24.0618 5.35727 26.154 7.64305 26.154 11.0003Z"
        fill="currentColor"
      />
      <path
        d="M64.5582 12.7137C64.5582 16.9281 61.5693 20.0711 57.4596 20.0711C55.8158 20.0711 54.3961 19.571 53.35 18.6425C53.2752 18.571 53.0511 18.6424 53.0511 18.7139V23.8569C53.0511 23.9283 52.9764 23.9997 52.9016 23.9997H49.2403C49.1656 23.9997 49.0908 23.9283 49.0908 23.8569V5.85642C49.0908 5.78499 49.1656 5.7136 49.2403 5.7136H52.6027C52.6775 5.7136 52.7522 5.78499 52.7522 5.85642V7.35644C52.7522 7.42787 52.8269 7.42787 52.8269 7.35644C53.873 6.0707 55.5169 5.35645 57.4596 5.35645C61.5693 5.42788 64.5582 8.49932 64.5582 12.7137ZM60.5232 12.7137C60.5232 10.3565 58.8046 8.85654 56.7124 8.85654C54.8444 8.85654 52.9763 9.99936 52.9763 12.7137C52.9763 15.3566 54.8444 16.571 56.7124 16.571C58.8046 16.571 60.5232 15.1423 60.5232 12.7137Z"
        fill="currentColor"
      />
      <path
        d="M66.4302 13.5707V5.8562C66.4302 5.78477 66.5049 5.71338 66.5796 5.71338H70.3157C70.3904 5.71338 70.4652 5.78477 70.4652 5.8562V13.9279C70.4652 15.5707 71.6607 16.6421 73.2299 16.6421C74.7243 16.6421 75.9198 15.5707 75.9198 13.9279V5.8562C75.9198 5.78477 75.9946 5.71338 76.0693 5.71338H79.8054C79.8801 5.71338 79.9548 5.78477 79.9548 5.8562V13.5707C79.9548 17.4994 77.0407 20.0708 73.2299 20.0708C69.3443 20.0708 66.4302 17.4994 66.4302 13.5707Z"
        fill="currentColor"
      />
      <path
        d="M94.1514 16.4999H87.5759C87.5011 16.4999 87.4264 16.4285 87.5012 16.3571L92.8064 8.92835L94.3755 6.78544C94.3755 6.71401 94.3755 6.71396 94.3755 6.64253V5.85682C94.3755 5.71396 94.3008 5.64258 94.1514 5.64258H82.4201C82.2707 5.64258 82.1959 5.71396 82.1959 5.85682V8.71402C82.1959 8.78545 82.1959 8.78551 82.2706 8.85694C82.3454 8.92837 82.3454 8.92835 82.4201 8.92835H88.3231C88.3978 8.92835 88.4725 8.99975 88.3978 9.07118L81.5234 18.6428C81.5234 18.6428 81.5235 18.7142 81.4487 18.7857V19.5715C81.4487 19.7143 81.5234 19.7857 81.6729 19.7857H93.4042H94.1514C94.3008 19.7857 94.3755 19.7143 94.3755 19.5715V16.7857C94.3755 16.5714 94.3008 16.4999 94.1514 16.4999Z"
        fill="currentColor"
      />
      <path
        d="M108.495 16.4993H101.919C101.844 16.4993 101.77 16.4279 101.844 16.3565L107.075 8.99915L108.644 6.85625C108.644 6.78481 108.644 6.78485 108.644 6.71342V5.92762C108.644 5.78476 108.569 5.71338 108.42 5.71338H96.6887C96.5392 5.71338 96.4645 5.78476 96.4645 5.92762V8.78483C96.4645 8.85626 96.4645 8.85631 96.5392 8.92774C96.6139 8.99917 96.6139 8.99915 96.6887 8.99915H102.592C102.666 8.99915 102.741 9.07055 102.666 9.14198L95.792 18.7136C95.792 18.7136 95.792 18.7851 95.7173 18.8566V19.6423C95.7173 19.7851 95.792 19.8565 95.9414 19.8565H107.673H108.42C108.569 19.8565 108.644 19.7851 108.644 19.6423V16.8565C108.719 16.5708 108.644 16.4993 108.495 16.4993Z"
        fill="currentColor"
      />
      <path
        d="M111.41 0H114.997C115.146 0 115.221 0.0713779 115.221 0.214238V19.5719C115.221 19.7148 115.146 19.7861 114.997 19.7861H111.41C111.261 19.7861 111.186 19.7148 111.186 19.5719V0.214238C111.186 0.0713779 111.261 0 111.41 0Z"
        fill="currentColor"
      />
      <path
        d="M131.285 14.0003H121.496C121.347 14.0003 121.272 14.1432 121.272 14.2861C121.795 15.929 123.289 16.7861 125.307 16.7861C126.801 16.7861 128.072 16.2861 129.192 15.5004C129.267 15.4289 129.417 15.4289 129.491 15.5718L130.911 17.8576C130.986 17.929 130.911 18.0719 130.836 18.1433C129.192 19.4291 127.324 20.1433 125.157 20.1433C120.375 20.1433 116.938 16.7862 117.162 12.4289C117.312 8.50025 120.674 5.42871 124.859 5.42871C128.968 5.42871 131.733 8.2859 131.733 11.9288C131.733 12.6431 131.583 13.3575 131.509 13.8575C131.509 13.9289 131.434 14.0003 131.285 14.0003ZM121.496 11.3575H127.623C127.773 11.3575 127.847 11.286 127.847 11.1431C127.698 9.50023 126.278 8.57166 124.709 8.57166C123.14 8.57166 121.795 9.57166 121.272 11.1431C121.272 11.2146 121.347 11.3575 121.496 11.3575Z"
        fill="currentColor"
      />
      <path
        d="M42.2954 14.0003H32.5068C32.3574 14.0003 32.2826 14.1432 32.2826 14.2861C32.8057 15.929 34.3001 16.7861 36.3176 16.7861C37.8121 16.7861 39.0823 16.2861 40.2032 15.5004C40.2779 15.4289 40.4273 15.4289 40.502 15.5718L41.9218 17.8576C41.9965 17.929 41.9965 18.0719 41.847 18.1433C40.2032 19.4291 38.3351 20.1433 36.1682 20.1433C31.386 20.1433 27.9488 16.7862 28.173 12.4289C28.3224 8.50025 31.6849 5.42871 35.8693 5.42871C39.979 5.42871 42.7437 8.2859 42.7437 11.9288C42.7437 12.6431 42.5942 13.3575 42.5195 13.8575C42.4448 13.9289 42.3701 14.0003 42.2954 14.0003ZM32.4321 11.3575H38.5593C38.7087 11.3575 38.7834 11.286 38.7834 11.1431C38.634 9.50023 37.2143 8.57166 35.6451 8.57166C34.076 8.57166 32.731 9.57166 32.2079 11.1431C32.2079 11.2146 32.2827 11.3575 32.4321 11.3575Z"
        fill="currentColor"
      />
      <path
        d="M143.988 17.2138L145.781 15.2138C145.856 15.1423 146.006 15.1423 146.08 15.2138C147.201 16.2852 148.546 16.9281 150.041 16.9281C151.012 16.9281 151.909 16.571 151.909 15.7852C151.909 15.1424 151.311 14.8566 150.414 14.4995L148.845 13.8567C146.379 12.9281 144.885 11.7852 144.885 9.57084C144.885 6.8565 147.201 5.35645 150.265 5.35645C152.357 5.35645 154.075 5.99928 155.42 7.28503C155.495 7.35646 155.495 7.49933 155.42 7.57076L153.627 9.64226C153.552 9.71369 153.403 9.71369 153.328 9.64226C152.432 8.85652 151.311 8.42789 150.19 8.42789C149.219 8.42789 148.621 8.71363 148.621 9.42793C148.621 9.99937 149.069 10.2137 150.19 10.6423L151.61 11.2137C154.225 12.2137 155.719 13.2852 155.719 15.4995C155.719 18.3567 153.179 19.9996 150.041 19.9996C147.5 19.9996 145.408 18.9996 144.063 17.4282C143.913 17.4282 143.913 17.2853 143.988 17.2138Z"
        fill="currentColor"
      />
      <path
        d="M142.943 5.64194C142.644 5.57051 142.27 5.49902 141.822 5.49902C140.253 5.49902 138.833 6.14189 137.862 7.28478H137.787V5.99909C137.787 5.85623 137.637 5.78476 137.563 5.78476H134.424C134.275 5.78476 134.2 5.92766 134.2 5.99909V19.4993C134.2 19.6422 134.35 19.7136 134.424 19.7136H137.936C138.086 19.7136 138.16 19.5708 138.16 19.4993V12.785C138.16 10.2849 139.804 9.14194 142.121 9.14194H142.793H142.943C143.092 9.14194 143.167 9.07057 143.167 8.92771V8.78479V5.99909C143.167 5.7848 143.092 5.71337 142.943 5.64194Z"
        fill="currentColor"
      />
    </Box>
  );
};
