export const fontFamilies = {
  body: {
    normal: 'TheFuture-Regular, sans-serif',
    medium: 'TheFuture-Medium, sans-serif',
    bold: 'TheFuture-Bold, sans-serif'
  },
  primary: {
    normal: 'Signifier-Regular, serif',
    boldItalic: 'Signifier-BoldItalic, serif',
    light: 'Signifier-Light, serif'
  }
};
