import React from 'react';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { usePathMatch } from '../helper';
import { Box } from '@thepuzzlers/pieces';
import { AnimatePresence } from 'framer-motion';
import { MotionBox } from 'components/MotionElement';

export const NavigationLinkItem = ({
  label,
  to,
  sx,
  render,
  children,
  ...props
}) => {
  const { isPathMatch } = usePathMatch(to);
  const isLandingPage = usePathMatch('/').isPathMatch;
  const [showArrow, setShowArrow] = React.useState(false);
  const opacityLinkIndicator = isPathMatch || isLandingPage ? 1 : 0.5;

  // TODO: handle style change if path match
  return (
    <MotionBox
      onMouseOver={() => setShowArrow(true)}
      onMouseOut={() => setShowArrow(false)}
      sx={{
        position: 'relative',
        width: 'max-content',
        '& ~ *': {
          ml: [null, null, '7.4rem', '7.4rem']
        }
      }}>
      <NavigationLink
        to={to}
        sx={{
          variant: 'links.navigation',
          opacity: [opacityLinkIndicator, opacityLinkIndicator, 1, 1],
          ...sx
        }}
        {...props}>
        {label}
      </NavigationLink>
      <AnimatePresence>
        {/* {children} */}
        {/* /? children for decoration */}
        {(showArrow || isPathMatch) && children}
      </AnimatePresence>
    </MotionBox>
  );
};
