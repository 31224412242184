import React from 'react';
import { useRive } from '@rive-app/react-canvas';

import { gsap } from 'gsap';

// Local Components

const RiveComponent = ({ src, style, containerClassName, animateInView }) => {
  const { rive, RiveComponent: RiveContainer } = useRive({
    src,
    autoplay: animateInView ? false : true // set to false if handle by the scroll while in view
  });

  React.useEffect(() => {
    if (rive && animateInView && containerClassName) {
      const killAnimation = animateTheRiveWhenOnView(rive, containerClassName);
      return killAnimation;
    }
  }, [rive]);
  return (
    <RiveContainer
      style={{
        ...style
      }}
    />
  );
};

export default RiveComponent;

//? Prepared for animated rive while in view
const animateTheRiveWhenOnView = (rive, containerClassName) => {
  const animation = gsap.to(`.${containerClassName}`, {
    scrollTrigger: {
      trigger: `.${containerClassName}`,
      start: 'top 80%',
      onEnter: () => {
        rive && rive?.play();
      }
    }
  });

  return () => {
    animation?.kill();
    animation?.scrollTrigger?.kill();
  };
};
