import { graphql, useStaticQuery } from 'gatsby';
// import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useNavigationData = () => {
  // const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      navigationDataJson {
        menuBtn
        closeBtn
        pageLinks {
          label
          to
        }
        contactLink {
          label
          to
        }
        legalLink {
          label
          to
        }
      }
    }
  `);

  return data.navigationDataJson;
};
