import React from 'react';

// External Components
import { MotionBox } from 'components/MotionElement';

export const StudioLinkArrow = ({ sx, ...props }) => {
  return (
    <MotionBox
      as="svg"
      className="studio-link-arrow"
      viewBox="0 0 28 17"
      fill="none"
      sx={sx}
      {...props}>
      <path
        d="M0.999171 7.41068L2.25879 8.92506C5.04679 8.4795 7.27158 5.54887 7.4375 2.16208L6.17554 0.647705C6.01195 4.0345 3.78717 6.96512 0.999171 7.41068Z"
        fill="#6D679F"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999828 7.41064L1.02553 9.75514L2.28516 11.2695L2.2688 9.84266L2.25945 8.92502L0.999828 7.41064Z"
        fill="#6262A2"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.17702 0.647649L7.43898 2.16203L9.83203 1.8862L8.57241 0.371826L6.17702 0.647649Z"
        fill="#8774B5"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.45309 12.3064C5.93918 13.1551 6.25233 14.1762 6.33179 15.3033L7.59375 16.8177C7.55402 16.2448 7.45119 15.7011 7.29695 15.1919C6.9394 13.9932 6.28972 12.996 5.45309 12.3064Z"
        fill="#6D679F"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7912 1.53613L27.0508 3.05051L9.87178 5.80344L8.61216 4.28906L25.7912 1.53613Z"
        fill="#6D679F"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.259 8.92503L2.2847 11.2695C3.47188 11.1847 4.56791 11.5719 5.45362 12.3065C6.29026 12.9961 6.93993 13.9933 7.29749 15.1921C7.45172 15.7013 7.55455 16.245 7.59428 16.8178L9.98733 16.5393L9.94526 12.6195L27.1406 11.3915L27.0518 3.05052L25.8109 3.24943L9.87282 5.80345L9.83075 1.88623L7.4377 2.16205C7.27178 5.54885 5.04699 8.47947 2.259 8.92503Z"
        fill="#A29ACB"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.3756 1.7854L7.12109 2.67652"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.48667 2.67651L7.00781 3.30507"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.28354 7.71558L2.80469 8.34148"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3343 2.1355L25.8555 2.76406"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6638 2.68188L24.832 2.88327"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.1875 14.8578L5.97695 13.4839C5.97695 13.4839 5.68483 12.6353 5.45347 12.3064C5.45347 12.3064 6.53782 13.0649 7.1875 14.8578Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </MotionBox>
  );
};
