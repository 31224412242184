import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';
import thumbnail from 'assets/seo/thumbnail.png';
import { useSiteMetadata } from 'gatsby-theme-thepuzzlers-core';
import { useLocation } from '@reach/router';
export const EnhancedSeo = ({
  title,
  description,
  shortTitle,
  shortDescription
}) => {
  const siteMetaData = useSiteMetadata();
  const { pathname } = useLocation();

  const siteUrl = `${siteMetaData?.url}${pathname}`;

  return (
    <>
      <SEO
        title={title}
        description={description}
        shortTitle={shortTitle}
        shortDescription={shortDescription}
        url={siteUrl}
      />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
    </>
  );
};
