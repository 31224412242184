const sharedTransitionValue = {
  duration: 0.4
};

export const expandAnimation = {
  initial: {
    height: 0,
    transition: sharedTransitionValue
  },
  animate: {
    height: 'auto',
    transition: sharedTransitionValue
  },
  exit: {
    height: 0,
    transition: {
      ...sharedTransitionValue,
      delay: 0.2
    }
  }
};

export const fadeIn = {
  initial: {
    opacity: 0,
    transition: sharedTransitionValue
  },
  animate: {
    opacity: 1,
    transition: { ...sharedTransitionValue, delay: 0.2 }
  },
  exit: {
    opacity: 0,
    transition: sharedTransitionValue
  }
};

export const growingDividerLine = {
  initial: {
    height: 0,
    transition: {
      duration: 0.2
    }
  },
  animate: {
    height: '4px',
    transition: {
      duration: 0.2,
      delay: 0.4
    }
  },
  exit: {
    height: 0,
    transition: {
      duration: 0.2
    }
  }
};

export const showArrowAnimation = (rotation) => {
  return {
    initial: {
      x: 20,
      opacity: 0,
      rotate: rotation
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.2
      }
    }
  };
};
