import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const FooterIllustrationOne = ({ sx, ...props }) => {
  return (
    <Box
      className="footer-illustration-one"
      as="svg"
      viewBox="0 0 50 50"
      fill="none"
      sx={sx}
      {...props}>
      <path
        d="M4.36743 4.41323L19.0626 0.218445L32.9706 1.22913L20.7888 11.3986L4.36743 4.41323Z"
        fill="#E6BB8A"
      />
      <path
        d="M20.7887 11.6223C20.7887 11.6223 20.7261 11.6223 20.6993 11.6044L4.27795 4.61011C4.18851 4.57434 4.13485 4.4849 4.14379 4.38651C4.14379 4.28813 4.21534 4.20763 4.30478 4.18974L18.9999 0.0038836C18.9999 0.0038836 19.0536 -0.00506049 19.0804 0.0038836L32.9885 1.01457C33.0779 1.01457 33.1584 1.08614 33.1853 1.16663C33.2121 1.24713 33.1853 1.3455 33.1137 1.40811L20.9319 11.5775C20.9319 11.5775 20.8424 11.6312 20.7887 11.6312V11.6223ZM5.0382 4.4491L20.753 11.1393L32.4071 1.40811L19.0894 0.442163L5.0382 4.4491Z"
        fill="#1D1D1B"
      />
      <path
        d="M6.47846 25.28L0.333862 15.4237L4.36766 4.41348L20.789 11.3988L24.9212 20.2982L6.47846 25.28Z"
        fill="#ED6C5E"
      />
      <path
        d="M6.37998 25.5392L0.0922852 15.4503L4.23339 4.10916L20.9499 11.2287L20.9857 11.3091L25.2341 20.45L6.37998 25.5392ZM0.584213 15.4055L6.58569 25.0294L24.608 20.1638L20.619 11.5864L4.49278 4.71735L0.584213 15.4145V15.4055Z"
        fill="#1D1D1B"
      />
      <path
        d="M24.921 20.298L45.4566 25.2798L44.4102 7.80309L32.9707 1.2292L20.7888 11.3986L24.921 20.298Z"
        fill="#F3955B"
      />
      <path
        d="M45.4567 25.5035C45.4567 25.5035 45.4209 25.5035 45.403 25.5035L24.8674 20.5216C24.8048 20.5038 24.7422 20.459 24.7154 20.3964L20.5832 11.4971C20.5385 11.4076 20.5653 11.3003 20.6458 11.2287L32.8276 1.05932C32.8992 0.996707 32.9976 0.987745 33.0781 1.04141L44.5176 7.60637C44.5802 7.64215 44.6249 7.71371 44.6339 7.78526L45.6803 25.262C45.6803 25.3336 45.6535 25.4051 45.5998 25.4498C45.564 25.4856 45.5104 25.5035 45.4567 25.5035ZM25.0731 20.1102L45.2152 24.9937L44.1956 7.93732L32.9886 1.50652L21.0572 11.4702L25.0731 20.1192V20.1102Z"
        fill="#1D1D1B"
      />
      <path
        d="M6.47858 25.2799L0.333984 33.2312V15.4236L6.47858 25.2799Z"
        fill="#CF513C"
      />
      <path
        d="M0.333832 33.4547C0.333832 33.4547 0.289123 33.4547 0.262291 33.4458C0.17285 33.4189 0.110229 33.3295 0.110229 33.2311V15.4323C0.110229 15.334 0.172845 15.2445 0.27123 15.2177C0.369615 15.1909 0.467999 15.2267 0.521664 15.3161L6.66625 25.1725C6.71991 25.253 6.71097 25.3514 6.65731 25.4319L0.512724 33.3742C0.468004 33.4279 0.405384 33.4636 0.333832 33.4636V33.4547ZM0.557434 16.2105V32.5782L6.21011 25.2708L0.557434 16.2105Z"
        fill="#1D1D1B"
      />
      <path
        d="M24.9212 20.2979L26.1555 35.95L16.6211 40.0375L6.47852 25.2797L24.9212 20.2979Z"
        fill="#77384E"
      />
      <path
        d="M16.621 40.2611C16.5495 40.2611 16.4779 40.2254 16.4332 40.1628L6.29058 25.405C6.24586 25.3424 6.23692 25.2709 6.26375 25.1993C6.29058 25.1277 6.34425 25.083 6.4158 25.0651L24.8496 20.0833C24.9122 20.0654 24.9837 20.0833 25.0374 20.1191C25.0911 20.1548 25.1268 20.2174 25.1358 20.2801L26.379 35.9233C26.379 36.0216 26.3343 36.1111 26.2448 36.1469L16.7105 40.2343C16.7105 40.2343 16.6478 40.2522 16.621 40.2522V40.2611ZM6.84512 25.414L16.7015 39.7603L25.9229 35.807L24.7154 20.5841L6.84512 25.414Z"
        fill="#1D1D1B"
      />
      <path
        d="M45.457 25.2797L35.1892 40.0375L26.1557 35.95L24.9214 20.2979L45.457 25.2797Z"
        fill="#BD556C"
      />
      <path
        d="M35.189 40.2613C35.189 40.2613 35.1264 40.2613 35.0996 40.2434L26.075 36.1559C26.0035 36.1202 25.9498 36.0486 25.9409 35.9681L24.6976 20.3249C24.6976 20.2533 24.7245 20.1818 24.7781 20.1371C24.8318 20.0923 24.9033 20.0745 24.9749 20.0924L45.5105 25.0742C45.5821 25.0921 45.6447 25.1458 45.6626 25.2084C45.6805 25.271 45.6805 25.3515 45.6358 25.4141L35.3679 40.1718C35.3232 40.2344 35.2606 40.2702 35.1801 40.2702L35.189 40.2613ZM26.3702 35.7982L35.1086 39.7604L45.0812 25.423L25.1627 20.5932L26.3702 35.7982Z"
        fill="#1D1D1B"
      />
      <path
        d="M0.333862 33.2312L15.4762 45.6635L16.6211 40.0376L6.47846 25.2799L0.333862 33.2312Z"
        fill="#553C47"
      />
      <path
        d="M15.4763 45.8872C15.4227 45.8872 15.378 45.8693 15.3332 45.8335L0.190894 33.3923C0.101453 33.3118 0.0835575 33.1776 0.15511 33.0792L6.29971 25.1369C6.34443 25.0832 6.41598 25.0385 6.48753 25.0474C6.55908 25.0474 6.62169 25.0832 6.66641 25.1458L16.809 39.9036C16.8448 39.9572 16.8537 40.0109 16.8448 40.0735L15.6999 45.6993C15.6821 45.7798 15.6284 45.8424 15.5568 45.8693C15.53 45.8693 15.5032 45.8782 15.4853 45.8782L15.4763 45.8872ZM0.647038 33.1865L15.3332 45.2521L16.3797 40.0825L6.46964 25.6556L0.647038 33.1865Z"
        fill="#1D1D1B"
      />
      <path
        d="M35.189 40.0376L26.1554 35.9502L16.621 40.0376L15.4762 45.6635L27.1303 44.9032L35.189 40.0376Z"
        fill="#974748"
      />
      <path
        d="M15.4763 45.8871C15.4137 45.8871 15.3511 45.8603 15.3064 45.8066C15.2617 45.753 15.2438 45.6814 15.2527 45.6188L16.3976 39.993C16.4155 39.9214 16.4602 39.8588 16.5317 39.8319L26.0661 35.7445C26.1287 35.7177 26.1913 35.7177 26.245 35.7445L35.2696 39.8319C35.3412 39.8677 35.3948 39.9393 35.4038 40.0198C35.4038 40.1003 35.368 40.1808 35.2964 40.2255L27.2378 45.0911C27.2378 45.0911 27.1752 45.1179 27.1394 45.1269L15.4942 45.8871C15.4942 45.8871 15.4853 45.8871 15.4763 45.8871ZM16.8179 40.1987L15.7536 45.422L27.05 44.6797L34.6972 40.0645L26.1466 36.1917L16.809 40.1987H16.8179Z"
        fill="#1D1D1B"
      />
      <path
        d="M45.4569 25.28L49.4102 35.9503L39.0977 44.9033L35.1891 40.0377L45.4569 25.28Z"
        fill="#D36760"
      />
      <path
        d="M39.0977 45.1268C39.0977 45.1268 39.0888 45.1268 39.0799 45.1268C39.0172 45.1268 38.9636 45.0911 38.9278 45.0463L35.0192 40.1808C34.9566 40.1003 34.9566 39.9929 35.0192 39.9124L45.2871 25.1547C45.3318 25.0831 45.4123 25.0474 45.5017 25.0563C45.5822 25.0652 45.6538 25.1189 45.6806 25.1994L49.6428 35.8697C49.6786 35.9591 49.6428 36.0575 49.5802 36.1201L39.2677 45.0732C39.2677 45.0732 39.1782 45.1268 39.1246 45.1268H39.0977ZM35.4665 40.0287L39.1246 44.5813L49.1509 35.8787L45.3944 25.7539L35.4665 40.0198V40.0287Z"
        fill="#1D1D1B"
      />
      <path
        d="M44.4108 7.80301L49.4105 35.9501L45.4572 25.2798L44.4108 7.80301Z"
        fill="#E6BB8A"
      />
      <path
        d="M49.4102 36.1738C49.3207 36.1738 49.2313 36.1201 49.2044 36.0307L45.2422 25.3604C45.2422 25.3604 45.2333 25.3157 45.2243 25.2978L44.1779 7.82104C44.1779 7.70477 44.2584 7.59743 44.3746 7.58849C44.4998 7.5706 44.5982 7.65111 44.6251 7.76738L49.6248 35.9144C49.6427 36.0307 49.5711 36.138 49.4638 36.1738C49.4459 36.1738 49.428 36.1738 49.4012 36.1738H49.4102ZM45.6715 25.2352L48.7572 33.5442L44.8666 11.6223L45.6805 25.2352H45.6715Z"
        fill="#1D1D1B"
      />
      <path
        d="M39.0976 44.9033H27.1304L35.189 40.0377L39.0976 44.9033Z"
        fill="#866D49"
      />
      <path
        d="M39.0975 45.127H27.1303C27.0319 45.127 26.9425 45.0644 26.9156 44.966C26.8888 44.8676 26.9335 44.7692 27.014 44.7155L35.0727 39.85C35.171 39.7963 35.2873 39.8142 35.3589 39.9036L39.2674 44.7692C39.3211 44.8408 39.33 44.9302 39.2943 45.0018C39.2585 45.0823 39.178 45.127 39.0885 45.127H39.0975ZM27.9263 44.6798H38.6324L35.1353 40.3329L27.9263 44.6798Z"
        fill="#1D1D1B"
      />
      <path
        d="M15.4762 45.6636L21.6655 48.5794L27.1303 44.9034L15.4762 45.6636Z"
        fill="#77384E"
      />
      <path
        d="M21.6654 48.8029C21.6654 48.8029 21.6028 48.8029 21.567 48.785L15.3777 45.8782C15.2882 45.8335 15.2346 45.7351 15.2525 45.6367C15.2703 45.5383 15.3598 45.4578 15.4582 45.4578L27.1034 44.6976C27.2286 44.6886 27.3001 44.7512 27.327 44.8496C27.3628 44.948 27.327 45.0553 27.2375 45.109L21.7816 48.785C21.7816 48.785 21.7011 48.8208 21.6564 48.8208L21.6654 48.8029ZM16.3526 45.8335L21.6475 48.3199L26.3163 45.1806L16.3615 45.8335H16.3526Z"
        fill="#1D1D1B"
      />
      <path
        d="M39.0977 44.9033L28.9998 49.5185L21.6656 48.5793L27.1305 44.9033H39.0977Z"
        fill="#BD556C"
      />
      <path
        d="M28.9997 49.742C28.9997 49.742 28.9819 49.742 28.9729 49.742L21.6477 48.8029C21.5583 48.794 21.4778 48.7224 21.4599 48.633C21.442 48.5435 21.4778 48.4451 21.5493 48.3915L27.0052 44.7155C27.0052 44.7155 27.0857 44.6797 27.1304 44.6797H39.0976C39.205 44.6797 39.2944 44.7513 39.3123 44.8586C39.3391 44.957 39.2854 45.0643 39.1871 45.109L29.0892 49.7242C29.0892 49.7242 29.0266 49.742 28.9997 49.742ZM22.2917 48.4273L28.964 49.2859L38.0691 45.1269H27.193L22.2827 48.4273H22.2917Z"
        fill="#1D1D1B"
      />
    </Box>
  );
};
