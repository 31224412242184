import React from 'react';

// External Components
import { MotionBox } from 'components/MotionElement';

export const ServicesLinkArrow = ({ sx, ...props }) => {
  return (
    <MotionBox
      as="svg"
      className="services-link-arrow"
      viewBox="0 0 26 12"
      fill="none"
      sx={sx}
      {...props}>
      <path
        d="M20.1328 7.62843L21.5251 8.39795V6.31351L20.1328 5.54597V7.62843Z"
        fill="#F37263"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0007 4.31831L21.5249 8.39795V6.31351L1.91406 8.2492L1.91406 5.33971L21.5249 3.40402V1.31957L27.0007 4.31831Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.519531 7.48173L1.91379 8.24927L1.91379 5.33978L0.519531 4.57026L0.519531 7.48173Z"
        fill="#F37263"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1323 2.63463L21.5246 3.40415L1.91379 5.33984L0.519531 4.57033L20.1323 2.63463Z"
        fill="#C65A56"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1328 2.63453L21.5251 3.40405V1.31961L20.1328 0.550093V2.63453Z"
        fill="#F37263"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5251 2.96773L20.1328 1.68652V2.63453L21.5251 3.40405V2.96773Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0237 3.31274L18.4922 3.01922"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8753 3.55078L16.3438 3.25725"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5711 3.55078L16.875 3.16602"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.47348 5.60156L0.777344 5.21879"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.880475 6.20239L0.777344 6.14488"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.01137 5.81958L0.777344 5.69067"
        stroke="#231F20"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </MotionBox>
  );
};
