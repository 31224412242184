import React from 'react';
import PropTypes from 'prop-types';
import { CoreLayout } from 'gatsby-theme-thepuzzlers-core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import './fontSize.css';
import riveWasmUrl from '@rive-app/canvas/rive.wasm';
import { RuntimeLoader } from '@rive-app/react-canvas';

// Sections
import { Footer, Navigation } from 'sections';

import 'gatsby-plugin-theme-ui/fonts/fontImports';
import { Spacer } from 'components';
import { colors } from 'gatsby-plugin-theme-ui/colors';
import { useTransparentBackgroundCheck } from 'sections/Navigation/helper/useTransparentBackroundCheck';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, DrawSVGPlugin);
RuntimeLoader.setWasmUrl(riveWasmUrl);

const Layout = ({ children, pageContext }) => {
  const isTransparentBackground = useTransparentBackgroundCheck();

  let lastHeight = 0;
  let lastWidth = 0;

  const setDocHeight = () => {
    if (
      Math.abs(lastWidth - window.visualViewport.width) > 100 ||
      Math.abs(lastHeight - window.visualViewport.height) > 100
    ) {
      lastHeight = window.visualViewport.height;
      lastWidth = window.visualViewport.width;

      document.documentElement.style.setProperty(
        '--vh',
        `${window.visualViewport.height / 100}px`
      );
    }
  };

  React.useEffect(() => {
    setDocHeight();
    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);
  }, []);

  return (
    // CoreLayout includes PageContextProvider & Global component
    <CoreLayout
      pageContext={pageContext}
      styles={{
        backgroundColor: isTransparentBackground
          ? 'transparent'
          : colors.background
      }}>
      <Navigation />
      {children}
      <Footer isTransparentBackground={isTransparentBackground} />
    </CoreLayout>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Layout;
