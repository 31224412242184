import React, { Suspense } from 'react';
import { AspectRatioBox } from './AspectRatioBox';
const RiveComponent = React.lazy(() => import('components/RiveComponent'));

export const AspectRatioRiveContainer = ({
  className,
  aspectRatio,
  src,
  animateInView,
  sx,
  ...props
}) => {
  return (
    <AspectRatioBox
      className={className}
      sx={{
        width: '100%',
        overflow: 'hidden',
        ...sx
      }}
      aspectRatio={aspectRatio}
      {...props}>
      <Suspense fallback={<div />}>
        <RiveComponent
          src={src}
          animateInView={animateInView}
          containerClassName={className}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
          }}
        />
      </Suspense>
    </AspectRatioBox>
  );
};
