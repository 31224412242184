import React from 'react';

// External Components
import { Overlay, Box } from '@thepuzzlers/pieces';
import { IllustrationOne } from 'components';
import { NavigationLinkItem } from '../components/NavigationLinkItem';
import { expandAnimation, fadeIn, showArrowAnimation } from './animation';
import { MotionBox, MotionGridWrapper } from 'components/MotionElement';
import { usePathChangeEffect } from 'hooks';
import { NavIllustrationTwo } from 'components/svgs/NavIllustrationTwo';
import { NavIllustrationThree } from 'components/svgs/NavIllustrationThree';
import { CaseStudyLinkArrow } from 'components/svgs/CaseStudyLinkArrow';
import { ServicesLinkArrow } from 'components/svgs/ServicesLinkArrow';
import { StudioLinkArrow } from 'components/svgs/StudioLinkArrow';
import { AboutLinkArrow } from 'components/svgs/AboutLinkArrow';

export const NavigationOverlay = ({ navigationData, handleClose }) => {
  usePathChangeEffect(() => {
    handleClose();
  });

  return (
    <Overlay
      handleClose={handleClose}
      shouldCloseOnBackdropClick
      shouldCloseOnEscapePress
      containerSx={{
        overflow: 'hidden'
      }}>
      <Content navigationData={navigationData} />
    </Overlay>
  );
};

const Content = ({ navigationData: { pageLinks } }) => {
  return (
    <MotionBox
      variants={expandAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        bg: 'background',
        overflow: 'hidden',
        position: 'relative'
      }}>
      <MotionGridWrapper
        variants={fadeIn}
        sx={{
          position: 'relative',
          pt: ['6.8rem', '6.8rem', null, null],
          // pb: ['40.3rem', '73.4rem', null, null],
          // animation values
          width: '100%',
          height: '100vh'
        }}>
        <NavigationLinks links={pageLinks} />
        <IllustrationOne
          initial={{
            y: 0,
            scale: 1
          }}
          animate={{
            y: 8,
            scale: 1.05
          }}
          transition={{
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 2
          }}
          sx={{
            width: ['5.745rem', '10.8rem', null, null],
            position: 'absolute',
            gridColumnStart: 1,
            bottom: ['15.1rem', '31.02rem', null, null],
            left: [0, 0, null, null]
          }}
        />
        <NavIllustrationTwo
          initial={{
            y: 0,
            x: 0,
            scale: 1
          }}
          animate={{
            y: -8,
            x: 3,
            scale: 1.05
          }}
          transition={{
            ease: 'easeIn',
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 2
          }}
          sx={{
            width: ['4.2rem', '7.125rem', null, null],
            position: 'absolute',
            gridColumnStart: 1,
            bottom: ['10.21rem', '17.7rem', null, null],
            left: ['10.2rem', '23.367rem', null, null]
          }}
        />
        <NavIllustrationThree
          initial={{
            y: 0,
            x: 0,
            scale: 1
          }}
          animate={{
            y: 10,
            x: -3,
            scale: 1.05
          }}
          transition={{
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 2.3
          }}
          sx={{
            width: ['4.426rem', '7.17rem', null, null],
            position: 'absolute',
            gridColumnStart: 1,
            bottom: ['25.54rem', '50.77rem', null, null],
            right: ['-1.1rem', '-1.1rem', null, null]
          }}
        />
      </MotionGridWrapper>
    </MotionBox>
  );
};

const NavigationLinks = ({ links }) => {
  const getDecoration = (label) => {
    if (label === 'Latest Case Study')
      return (
        <CaseStudyLinkArrow
          variants={showArrowAnimation(172)}
          initial="initial"
          animate="animate"
          sx={{
            position: 'absolute',
            top: ['0.2rem', '0.5rem'],
            right: ['-6.3rem', '-7.3rem'],
            width: ['5.413rem', '6.413rem']
          }}
        />
      );
    if (label === 'Agency')
      return (
        <ServicesLinkArrow
          variants={showArrowAnimation(180)}
          initial="initial"
          animate="animate"
          sx={{
            position: 'absolute',
            top: [0, '0.5rem'],
            right: ['-7.2rem', '-8.2rem'],
            width: ['5.648rem', '6.648rem']
          }}
        />
      );
    if (label === 'Studio')
      return (
        <StudioLinkArrow
          variants={showArrowAnimation(0)}
          initial="initial"
          animate="animate"
          sx={{
            position: 'absolute',
            top: ['0.3rem', '0.5rem'],
            right: ['-4.8rem', '-5.8rem'],
            width: ['3.814rem', '4.814rem']
          }}
        />
      );
    if (label === 'About')
      return (
        <AboutLinkArrow
          variants={showArrowAnimation(-10)}
          initial="initial"
          animate="animate"
          sx={{
            position: 'absolute',
            top: [0, '0.3rem'],
            right: ['-5.7rem', '-6.7rem'],
            width: ['4.54rem', '5.4rem']
          }}
        />
      );
  };

  return (
    <Box
      className="__navigation-links-container"
      sx={{
        gridColumn: ['1/13', '1/25', null, null],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        mt: ['7.4rem', '10rem', 0, 0],
        '& * + *': {
          mt: ['3.4rem', '4rem']
        }
      }}>
      {links.map(({ label, to }, index) => (
        <NavigationLinkItem
          key={index}
          label={label}
          to={to}
          sx={{
            fontSize: ['3.2rem', '4rem', null, null]
          }}>
          {getDecoration(label)}
        </NavigationLinkItem>
      ))}
    </Box>
  );
};
