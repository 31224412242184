import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const IllustrationOne = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M22.9399 14.6201L36.84 11.3101L42.5399 15.2001L42.5299 15.2401L28.6399 18.5101L22.9399 14.6201Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M42.5298 15.2402L41.2098 22.3202L27.2998 25.6302L28.6398 18.5102L42.5298 15.2402Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M28.6399 18.5101L27.2999 25.6301L21.6099 21.7401L22.9399 14.6201L28.6399 18.5101Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M27.3099 25.6202L21.6199 21.6802L14.9099 23.2502L14.8999 23.2902L20.5899 27.1802L27.3099 25.6202Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M14.8999 23.29L12.3599 36.84L18.0499 40.77L20.5899 27.18L14.8999 23.29Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M20.5898 27.1801L18.0498 40.7701L24.7698 39.2001L27.3098 25.6201L20.5898 27.1801Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M40.4099 26.6102L28.6399 18.4902L21.9299 20.0602L21.9199 20.1002L33.6899 28.1802L40.4099 26.6102Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M21.9198 20.1001L20.5898 27.1801L32.3598 35.2901L33.6898 28.1801L21.9198 20.1001Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M33.6899 28.1803L32.3599 35.2903L39.0699 33.7204L40.4099 26.6104L33.6899 28.1803Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M22.9502 14.5702L11.1902 6.4502L4.47021 8.0202V8.0602L16.2402 16.1402L22.9502 14.5702Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M4.47013 8.06006L3.14014 15.1401L14.9101 23.2501L16.2401 16.1401L4.47013 8.06006Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M16.2402 16.1403L14.9102 23.2503L21.6202 21.6803L22.9502 14.5703L16.2402 16.1403Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M31.18 4.92999L25.49 1L18.78 2.56L18.77 2.60999L24.46 6.5L31.18 4.92999Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M18.7702 2.60986L16.2402 16.1499L21.9202 20.0799L24.4602 6.49987L18.7702 2.60986Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M24.4604 6.50018L21.9204 20.0802L28.6404 18.5202L31.1804 4.93018L24.4604 6.50018Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M2.33008 19.4799L16.2301 16.1699L21.9301 20.0599L21.9201 20.0999L8.02008 23.3799L2.33008 19.4799Z"
        fill="#FBB040"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M21.9199 20.1001L20.59 27.1801L6.68994 30.4901L8.01994 23.3801L21.9199 20.1001Z"
        fill="#E8654A"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.02 23.38L6.68999 30.49L1 26.6L2.32999 19.48L8.02 23.38Z"
        fill="#F79460"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </Box>
  );
};
