import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const ThreeDimensionArrow = ({ sx, ...props }) => {
  return (
    <Box
      className="three-dimensional-arrow"
      as="svg"
      viewBox="0 0 38 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M16.4855 18.9796L13.4421 19.9995L5.58374 12.2403L8.62714 11.2203L16.4855 18.9796Z"
        fill="#6E374E"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4855 6.104L13.4421 7.11985L5.58374 12.2404L8.62714 11.2204L16.4855 6.104Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0415 17.365L15.998 19.2604V25.9625L19.0415 24.9425V17.365Z"
        fill="#9C5374"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2399 9.75439L34.1965 10.7744V23.3196L37.2399 22.2996V9.75439Z"
        fill="#9C5374"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2399 9.75446L34.1965 10.7744L15.998 8.71797L19.0415 7.698L37.2399 9.75446Z"
        fill="#6E374E"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0646 18.7731L31.0212 19.7931L13.4421 16.2335L16.4855 15.2135L34.0646 18.7731Z"
        fill="#6E374E"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0415 1L15.998 2.01997V8.71793L19.0415 7.69796V1Z"
        fill="#9C5374"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9981 25.9625V19.2646L34.1966 23.3238V10.7786L15.9981 8.72211V2.02002L1 11.4723L15.9981 25.9625ZM13.4379 16.2336L13.4379 19.9996L5.57955 12.2404L13.4379 7.11988V10.8859L31.0169 13.2315V19.7931L13.4379 16.2336Z"
        fill="#B9677E"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0415 20.6437L15.998 22.1469V19.2604L19.0415 19.9417V20.6437Z"
        fill="#231F20"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8406 24.8558L18.3602 24.3479"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.9604 11.939L36.4801 11.431"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.9604 13.1034L35.7203 12.8474"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4146 23.7698L18.1744 23.5138"
        stroke="#231F20"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  );
};
