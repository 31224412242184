import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const StackedThePuzzlersLogo = ({ sx, ...props }) => {
  return (
    <Box
      className="thepuzzlers-mobile-logo"
      as="svg"
      viewBox="0 0 75 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'texts.primaryInvert',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M6.92422 11.0952V13.0953C6.92422 13.1429 6.92424 13.1429 6.87442 13.1429C6.32646 13.2858 6.07739 13.3334 5.37998 13.3334C2.98888 13.3334 1.49444 12.2381 1.49444 9.8095V5.95227C1.49444 5.90465 1.44462 5.90466 1.44462 5.90466H0.0498176C3.0397e-06 5.90466 0 5.85699 0 5.85699V3.80936C0 3.76173 0.0498176 3.76175 0.0498176 3.76175H1.44462C1.49443 3.76175 1.49444 3.71408 1.49444 3.71408V1.90454C1.49444 1.85692 1.54425 1.85693 1.54425 1.85693H4.0848C4.13462 1.85693 4.13462 1.90454 4.13462 1.90454V3.71408C4.13462 3.7617 4.18442 3.76175 4.18442 3.76175H6.82459C6.8744 3.76175 6.87442 3.80936 6.87442 3.80936V5.85699C6.87442 5.90461 6.82459 5.90466 6.82459 5.90466H4.18442C4.13461 5.90466 4.13462 5.95227 4.13462 5.95227V9.5714C4.13462 10.6667 4.73238 11.0476 5.92793 11.0476C6.22682 11.0476 6.32644 11.0476 6.82459 11C6.8744 11.0476 6.92422 11.0952 6.92422 11.0952Z"
        fill="currentColor"
      />
      <path
        d="M17.4359 7.33354V13.048C17.4359 13.0956 17.3861 13.1432 17.3363 13.1432H14.8455C14.7957 13.1432 14.7459 13.0956 14.7459 13.048V7.66686C14.7459 6.57159 13.9987 5.85726 12.9526 5.85726C11.8567 5.85726 11.1094 6.57159 11.1094 7.66686V13.048C11.1094 13.0956 11.0596 13.1432 11.0098 13.1432H8.51907C8.46925 13.1432 8.41943 13.0956 8.41943 13.048V0.0952174C8.41943 0.0475971 8.46925 0 8.51907 0H11.0098C11.0596 0 11.1094 0.0475971 11.1094 0.0952174V4.42865C11.1094 4.47627 11.2091 4.52394 11.2589 4.47632C11.9065 3.90488 12.8031 3.57152 13.7994 3.57152C16.0411 3.57152 17.4359 5.09539 17.4359 7.33354Z"
        fill="currentColor"
      />
      <path
        d="M13.3116 22.4757C13.3116 25.2853 11.319 27.3806 8.57923 27.3806C7.48331 27.3806 6.53684 27.0472 5.83943 26.4282C5.78962 26.3805 5.64019 26.4282 5.64019 26.4758V29.9045C5.64019 29.9521 5.59037 29.9997 5.54056 29.9997H3.09964C3.04982 29.9997 3 29.9521 3 29.9045V17.9041C3 17.8565 3.04982 17.8089 3.09964 17.8089H5.34129C5.3911 17.8089 5.44092 17.8565 5.44092 17.9041V18.9041C5.44092 18.9518 5.49073 18.9518 5.49073 18.9041C6.18813 18.047 7.28405 17.5708 8.57923 17.5708C11.319 17.6184 13.3116 19.6661 13.3116 22.4757ZM10.6216 22.4757C10.6216 20.9042 9.47589 19.9042 8.08109 19.9042C6.83572 19.9042 5.59036 20.6661 5.59036 22.4757C5.59036 24.2376 6.83572 25.0472 8.08109 25.0472C9.47589 25.0472 10.6216 24.0947 10.6216 22.4757Z"
        fill="currentColor"
      />
      <path
        d="M14.5596 23.0473V17.9043C14.5596 17.8567 14.6094 17.8091 14.6592 17.8091H17.1499C17.1998 17.8091 17.2496 17.8567 17.2496 17.9043V23.2854C17.2496 24.3807 18.0466 25.095 19.0927 25.095C20.089 25.095 20.886 24.3807 20.886 23.2854V17.9043C20.886 17.8567 20.9359 17.8091 20.9857 17.8091H23.4764C23.5262 17.8091 23.576 17.8567 23.576 17.9043V23.0473C23.576 25.6664 21.6333 27.3808 19.0927 27.3808C16.5024 27.3808 14.5596 25.6664 14.5596 23.0473Z"
        fill="currentColor"
      />
      <path
        d="M33.0402 25H28.6565C28.6067 25 28.5569 24.9524 28.6067 24.9048L32.1436 19.9522L33.1897 18.5236C33.1897 18.476 33.1897 18.476 33.1897 18.4284V17.9045C33.1897 17.8093 33.1399 17.7617 33.0402 17.7617H25.2194C25.1197 17.7617 25.0699 17.8093 25.0699 17.9045V19.8094C25.0699 19.857 25.0699 19.857 25.1197 19.9046C25.1695 19.9523 25.1695 19.9522 25.2194 19.9522H29.1547C29.2045 19.9522 29.2543 19.9998 29.2045 20.0475L24.6216 26.4286C24.6216 26.4286 24.6216 26.4762 24.5718 26.5238V27.0477C24.5718 27.1429 24.6216 27.1905 24.7212 27.1905H32.5421H33.0402C33.1399 27.1905 33.1897 27.1429 33.1897 27.0477V25.1905C33.1897 25.0476 33.1399 25 33.0402 25Z"
        fill="currentColor"
      />
      <path
        d="M42.6023 24.9997H38.2186C38.1688 24.9997 38.119 24.9521 38.1688 24.9045L41.6558 19.9996L42.7019 18.571C42.7019 18.5234 42.7019 18.5234 42.7019 18.4758V17.9519C42.7019 17.8567 42.6521 17.8091 42.5525 17.8091H34.7316C34.6319 17.8091 34.5821 17.8567 34.5821 17.9519V19.8567C34.5821 19.9043 34.5821 19.9044 34.6319 19.952C34.6817 19.9996 34.6818 19.9996 34.7316 19.9996H38.6669C38.7167 19.9996 38.7665 20.0472 38.7167 20.0948L34.1338 26.476C34.1338 26.476 34.1338 26.5236 34.084 26.5712V27.095C34.084 27.1903 34.1338 27.2379 34.2334 27.2379H42.0543H42.5525C42.6521 27.2379 42.7019 27.1903 42.7019 27.095V25.2378C42.7517 25.0474 42.7019 24.9997 42.6023 24.9997Z"
        fill="currentColor"
      />
      <path
        d="M44.5464 14H46.9375C47.0371 14 47.0869 14.0476 47.0869 14.1428V27.048C47.0869 27.1432 47.0371 27.1908 46.9375 27.1908H44.5464C44.4468 27.1908 44.397 27.1432 44.397 27.048V14.1428C44.397 14.0476 44.4468 14 44.5464 14Z"
        fill="currentColor"
      />
      <path
        d="M57.7959 23.3336H51.2702C51.1705 23.3336 51.1207 23.4288 51.1207 23.5241C51.4694 24.6193 52.4657 25.1908 53.8107 25.1908C54.807 25.1908 55.6538 24.8574 56.4011 24.3336C56.4509 24.286 56.5505 24.286 56.6003 24.3812L57.5468 25.9051C57.5966 25.9527 57.5468 26.048 57.497 26.0956C56.4011 26.9527 55.1557 27.4289 53.7111 27.4289C50.523 27.4289 48.2315 25.1908 48.3809 22.286C48.4806 19.6668 50.7222 17.6191 53.5118 17.6191C56.2516 17.6191 58.0948 19.5239 58.0948 21.9526C58.0948 22.4288 57.9951 22.905 57.9453 23.2384C57.9453 23.286 57.8955 23.3336 57.7959 23.3336ZM51.2702 21.5717H55.355C55.4546 21.5717 55.5044 21.524 55.5044 21.4288C55.4048 20.3335 54.4583 19.7144 53.4122 19.7144C52.3661 19.7144 51.4694 20.3811 51.1207 21.4288C51.1207 21.4764 51.1705 21.5717 51.2702 21.5717Z"
        fill="currentColor"
      />
      <path
        d="M28.1968 9.33358H21.6711C21.5714 9.33358 21.5216 9.42883 21.5216 9.52407C21.8703 10.6193 22.8666 11.1908 24.2116 11.1908C25.2079 11.1908 26.0548 10.8574 26.802 10.3336C26.8518 10.286 26.9514 10.286 27.0012 10.3812L27.9477 11.9051C27.9975 11.9527 27.9975 12.048 27.8979 12.0956C26.802 12.9527 25.5566 13.4289 24.112 13.4289C20.9238 13.4289 18.6324 11.1908 18.7818 8.28596C18.8814 5.66684 21.1231 3.61914 23.9127 3.61914C26.6525 3.61914 28.4957 5.52394 28.4957 7.95258C28.4957 8.42878 28.396 8.90502 28.3462 9.23836C28.2964 9.28598 28.2466 9.33358 28.1968 9.33358ZM21.6212 7.57165H25.706C25.8057 7.57165 25.8555 7.52401 25.8555 7.42877C25.7559 6.3335 24.8094 5.71445 23.7633 5.71445C22.7172 5.71445 21.8205 6.38112 21.4718 7.42877C21.4718 7.47639 21.5216 7.57165 21.6212 7.57165Z"
        fill="currentColor"
      />
      <path
        d="M66.2646 25.4758L67.4602 24.1424C67.51 24.0947 67.6097 24.0947 67.6595 24.1424C68.4067 24.8567 69.3034 25.2853 70.2996 25.2853C70.9472 25.2853 71.545 25.0472 71.545 24.5234C71.545 24.0948 71.1465 23.9043 70.5487 23.6662L69.5026 23.2376C67.8587 22.6186 66.8624 21.8566 66.8624 20.3804C66.8624 18.5708 68.4067 17.5708 70.4491 17.5708C71.8439 17.5708 72.9896 17.9994 73.8863 18.8565C73.9361 18.9041 73.9361 18.9994 73.8863 19.047L72.6907 20.428C72.6409 20.4756 72.5413 20.4756 72.4915 20.428C71.8937 19.9042 71.1465 19.6184 70.3993 19.6184C69.7517 19.6184 69.3532 19.8089 69.3532 20.2851C69.3532 20.6661 69.6521 20.809 70.3993 21.0947L71.3457 21.4756C73.0893 22.1423 74.0855 22.8566 74.0855 24.3329C74.0855 26.2377 72.3919 27.333 70.2996 27.333C68.606 27.333 67.2111 26.6663 66.3145 25.6186C66.2149 25.6186 66.2148 25.5234 66.2646 25.4758Z"
        fill="currentColor"
      />
      <path
        d="M65.568 17.7613C65.3688 17.7137 65.1197 17.666 64.8208 17.666C63.7747 17.666 62.8283 18.0946 62.1807 18.8565H62.1308V17.9994C62.1308 17.9042 62.0312 17.8565 61.9814 17.8565H59.8892C59.7896 17.8565 59.7397 17.9518 59.7397 17.9994V26.9996C59.7397 27.0948 59.8394 27.1425 59.8892 27.1425H62.2305C62.3301 27.1425 62.3799 27.0472 62.3799 26.9996V22.5233C62.3799 20.8566 63.4758 20.0946 65.0201 20.0946H65.4684H65.568C65.6677 20.0946 65.7175 20.0471 65.7175 19.9518V19.8565V17.9994C65.7175 17.8565 65.6677 17.8089 65.568 17.7613Z"
        fill="currentColor"
      />
    </Box>
  );
};
