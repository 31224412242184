import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const IntroIllustration = ({ sx, ...props }) => {
  return (
    <Box
      className="intro-illustration"
      as="svg"
      viewBox="0 0 44 43"
      fill="none"
      sx={sx}
      {...props}>
      <path
        d="M43.74 21.37C43.74 33.18 34.17 42.75 22.37 42.75C10.57 42.75 1 33.18 1 21.37C1 18.91 1.42 16.55 2.19 14.35C5.09 6.00001 13.03 0 22.37 0C23.12 0 23.87 0.0399951 24.6 0.119995C35.36 1.23 43.74 10.32 43.74 21.37Z"
        fill="#E55C40"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M22.37 42.75C22.37 42.75 11.02 39.49 11.02 22.36C11.02 17.79 12.25 12.69 14.39 8.44995"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="10 10"
      />
      <path
        d="M36.9799 32.5799C38.3 29.8999 39.1799 26.5599 39.1799 22.3999C39.1799 9.07993 32.3799 3.26994 25.1799 0.189941"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="10 10"
      />
      <path
        d="M24.6 12.26C24.6 12.26 25.71 20.94 25.64 29.53"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="10 10"
      />
    </Box>
  );
};
