import { colors } from './colors';

const inputDefault = {
  borderRadius: '2px',
  color: 'text',
  fontFamily: 'body.normal',
  fontSize: ['1.4rem', '1.4rem', '1.4rem', '1.4rem'],
  lineHeight: 1,
  mt: '0.6rem',
  outline: 'none',
  p: [
    '1.5rem 1.2rem 1.3rem 1.2rem',
    '1.6rem 1.2rem 1.6rem 1.2rem',
    '1.4rem 1.2rem 1.4rem 1.2rem',
    '1.5rem 1.2rem 1.3rem 1.2rem'
  ],
  '::placeholder': {
    color: 'text',
    opacity: 0.5
  },
  bg: 'backgroundDarker',
  boxSizing: 'borderBox',
  '&:focus': {
    //? make the border inside the input element, to prevent push other element,
    boxShadow: `inset 0 0 0 2px ${colors.text}`
  }
};

export const forms = {
  label: {
    fontFamily: 'body.medium',
    fontSize: ['1.1rem', '1.1rem', '1.1rem', '1.1rem'],
    lineHeight: 1,
    textTransform: 'uppercase',
    m: 0
  },
  input: {
    ...inputDefault
  },
  textarea: {
    ...inputDefault
  },
  checkbox: {
    ...inputDefault,
    p: 0,
    mt: 0,
    border: 'none',
    color: 'secondary',
    'input:checked ~ &': {
      color: 'secondary'
    }
  },
  radio: {
    p: 0
  }
};
